import React from 'react'
import { Link } from "react-router-dom"

import AlliedBox from '../molecules/AlliedBox'
import { sendDataLayer } from '../../utils/functions'

function AlliancesGallery({
  alliedBoxType,
  itemsPerLine,
  limit,
  termsOnModal,
  list,
  termText,
}) {

  const layerTerms = () => {
    sendDataLayer({datalayer: { event: 'alliances', text: 'Conoce detalles y todas las alianzas', link: 'https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html' },repeat: false})
  }
  

  return (
    <div className={`mc-alliances-container mc-alliances-column-${itemsPerLine}`}>
      
      {list &&
        <div className="mc-alliances-container__items">
          {list.filter((item, i) => {

            return limit ? i < limit ? true : false : true
          }).map((item, index) => {

            return (
              <div key={index} className={`mc-alliances-container__items--item type-${alliedBoxType}`}>
                <AlliedBox
                  type={alliedBoxType}
                  allied={item}
                  termLink={item.termLink}
                  termText={termText}
                  termsOnModal={termsOnModal}
                  termsData={item.termsData}
                />
              </div>
            )
          })}
        </div>
      }

      <div className="mc-alliances-container__actions">
        <Link onClick={layerTerms} to="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html" target='_blank'>Conoce detalles y todas las alianzas</Link>
      </div>

    </div>
  )
}

export default AlliancesGallery