import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import {
  useLocation
} from 'react-router-dom'

import { StateController } from './../../App'

import VerticalCenter from '../VerticalCenter/VerticalCenter'
import Container from '../Container/Container'
import Icon from '../../components/atoms/Icon'
import Profile from '../../components/atoms/Profile'
import useWidth from '../../hooks/useWidth'
import { sendDataLayer } from '../../utils/functions'

function Header({
  page,
  headerType,
  logo,
  items,
  submenu,
  loggedIn,
  logOutAction
}) {
  const { context } = useContext(StateController)
  const { width } = useWidth()
  const location = useLocation()
  const [showOthers, setShowOthers] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const isSubLevel = (children) => {
    const page = location.pathname.replace('/', '')
    const data = children.find((item) => item.page === page)

    return data ? true : false
  }

  const togglerMenu = (e) => {
    setShowOthers(!showOthers)
    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('.mc-header-container__nav--button .mc-app-centry > span').innerText
      let link = 'none'

      sendDataLayer({ datalayer: { event: 'menu', text: text, link: link }, repeat: true })
    }

    if (e.target.closest('a')) {
      let text = e.target.closest('a').querySelector('span').innerText
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({ datalayer: { event: 'menu', text: text, link: link }, repeat: true })
    }

  }

  const toogleSumMenu = (e) => {
    setShowMenu(!showMenu)
    if (e.target.closest('a')) {
      let text = e.target.closest('a').querySelector('span').innerText
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({ datalayer: { event: 'menu', text: text, link: link }, repeat: true })
    }

  }

  const closeSumMenu = () => {
    setShowMenu(false)
  }

  const addDataLayer = (e) => {
    if (e.target.closest('.mc-header-container__nav--item')) {
      let text = e.target.closest('.mc-header-container__nav--item').querySelector('a .mc-app-centry > span').innerText
      let link = e.target.closest('.mc-header-container__nav--item').querySelector('a').getAttribute('href')
      sendDataLayer({ datalayer: { event: 'menu', text: text, link: link }, repeat: true })
    }
  }

  const handleClick = (e) => {
    addDataLayer(e);
    closeSumMenu();
  };

  useEffect(() => {
    const child = items.find((item) => item.sublevel)

    if (child) {
      setHasChildren(child)
    }
  }, [items])

  useEffect(() => {

    window.addEventListener('click', ({ target }) => {
      const window = target.closest('.mc-header-container__nav--extra')
      const button = target.closest('.mc-header-container__nav--button')

      if (window === null && button === null) setShowOthers(false)
    })
  }, [])

  const closeSession = () => {
    logOutAction()
    setShowOthers(false)
    sendDataLayer({ datalayer: { event: 'menu', text: 'Cerrar sesión', link: '/' }, repeat: true })
  }

  return (
    <div
      className={`
      mc-header-container mc-header-container-type-${headerType} 
      mc-header-container-page-${page !== '' ? page ? page : 'login' : 'login'} 
      ${showMenu ? 'show-menu' : ''}`
      }>
      <div className='mc-header-container__menu'>
        <Container fullHeight={true}>

          <a className="mc-header-container__logo" href="/">
            <VerticalCenter>
              <img src={`/assets/images/${logo}`} alt="Logo Banco de Occidente" />
            </VerticalCenter>
          </a>

          {headerType === 1 && loggedIn &&
            <div className="mc-header-container__mobile--close" onClick={closeSession}>
              <VerticalCenter>
                <Icon name="close_session" />
              </VerticalCenter>
            </div>
          }

          {headerType === 4 &&
            <div className="mc-header-container__mobile--submenu" onClick={toogleSumMenu}>
              <VerticalCenter>
                <Icon name="menu" />
              </VerticalCenter>
            </div>
          }

          <nav className="mc-header-container__nav">
            {headerType === 4 &&
              <div
                className="mc-header-container__mobile--overlay"
                onClick={closeSumMenu}
              >
              </div>
            }
            {headerType === 4 &&
              <div className="mc-header-container__nav--close" onClick={closeSumMenu}>
                <Icon name="close" />
              </div>
            }

            {loggedIn
              ? <ul className='containerMenuNav'>
                <hr className="custom-hr" />
                {items.filter((item) => {
                  if (item?.component?.props?.privatePage || item?.sublevel) {
                    return true
                  } else {
                    return width < 992 ? true : false
                  }
                }).map((item, index) => {
                  return (
                    item.sublevel
                      ? <li
                        className={`mc-header-container__nav--item slow_ani ${item.page} ${((showOthers) || (isSubLevel(item.children))) ? 'mc-header-container__nav--item-active' : ''}`}
                        onClick={togglerMenu}
                        key={index}
                      >
                        <div className="mc-header-container__nav--button">
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </div>
                      </li>
                      : <li
                        className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/', '') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`}
                        onClick={handleClick}
                        key={index}
                      >
                        <Link to={`/${item.page}`}>
                          <VerticalCenter>
                            <span>
                              {item.icon &&
                                <Icon name={item.icon} />
                              }
                              {item.title}
                            </span>
                          </VerticalCenter>
                        </Link>
                      </li>
                  )
                })}
                <li className='mc-header-container__nav--item slow_ani'>
                </li>
                <hr className="custom-hr" />
                <button className='sesionCloseBtn' onClick={closeSession}>Cerrar sesión <img src={`/assets/images/move_item.png`} alt="" /></button>
              </ul>
              : <ul>

                {items.filter((item) => {
                  if (item?.component?.props?.privatePage || item?.sublevel) {
                    return false
                  } else {
                    return true
                  }
                }).map((item, index) => {
                  return (
                    <li
                      className={`mc-header-container__nav--item item-${item.page} slow_ani ${location.pathname.replace('/', '') === item.page && !showOthers ? 'mc-header-container__nav--item-active' : ''}`}
                      onClick={addDataLayer}
                      key={index}
                    >
                      <Link to={`/${item.page}`}>
                        <VerticalCenter>
                          <span>
                            {item.icon &&
                              <Icon name={item.icon} />
                            }
                            {item.title}
                          </span>
                        </VerticalCenter>
                      </Link>
                    </li>
                  )
                })}

                <li className={`mc-header-container__nav--item-active slow_ani`}>
                  <Link to="/" className='mc-header-link'>
                    {/* <span>Ingresar</span> */}
                  </Link>
                </li>
              </ul>
            }

            {hasChildren &&
              <div className={`mc-header-container__nav--extra slow_ani ${showOthers ? 'opened' : ''}`}>
                {hasChildren.children.map((item, index) => {
                  return (
                    <Link to={`/${item.page}`} key={index} onClick={togglerMenu}>
                      <span>
                        {item.icon &&
                          <Icon name={item.icon} />
                        }
                        {item.title}
                      </span>
                    </Link>
                  )
                })}
                <div className="mc-header-container__nav--close" onClick={closeSession}>
                  Cerrar sesión <img style={{ verticalAlign: 'sub' }} src={`/assets/images/move_item.png`} />
                </div>
              </div>
            }
          </nav>
        </Container>
      </div>

      {submenu &&
        <div className={`mc-header-container__submenu ${!loggedIn ? 'not-login' : ''}`}>
          <Container fullHeight={true}>
            <VerticalCenter>
              {submenu.type === 'labels' &&
                <>
                  {submenu.icon && <Icon name={submenu.icon} />}
                  <div dangerouslySetInnerHTML={{ __html: submenu.content }} />
                </>
              }
              {submenu.type === 'nickname' && loggedIn && context?.user?.nickname &&
                <Profile
                  nickname={context.user.nickname}
                  defaultLetter={submenu.default}
                />
              }
            </VerticalCenter>
          </Container>
        </div>
      }
    </div>
  )
}

export default Header