import React, { useContext, useState, useEffect } from 'react';
import { StateController } from './../../App';
import Preloader from './Preloader';

function GoalCounter({ label, simpleVersion }) {
  const { context } = useContext(StateController);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (context && context.user && context.tracing) {
      const user = context.user;
      const tracing = context.tracing;
      
      const goalsMet1 = Math.floor(tracing[`amount_1`] / user[`goal_amount_1`]);
      const goalsMet2 = Math.floor(tracing[`amount_2`] / user[`goal_amount_2`]);
      
      const totalGoalsMet = goalsMet1 + goalsMet2;

      setCounter(totalGoalsMet);
    } else {
      setCounter(0);
    }
  }, [context]);

  return (
    <div className={`mc-goal-counter ${simpleVersion ? 'is-simple-version' : ''}`}>
      <div>
        {context ? (
          <>
            {label} <strong>{counter}</strong> {counter === 1 ? 'vez' : 'veces'}
            {!simpleVersion &&
              <div>
                Se registraron las compras que realizaste desde el DD/MM/AAAA hasta el DD/MM/AAAA.
              </div>
            }

          </>
        ) : (
          <Preloader type="section" invert />
        )}
      </div>
    </div>
  );
}

export default GoalCounter;
