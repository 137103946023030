import React, { useContext } from 'react'
import { StateController } from './../../App';
import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
// import SliderRotator from '../organisms/SliderRotator'
import CardBox from '../molecules/CardBox'
import useWidth from '../../hooks/useWidth'
import Preloader from '../atoms/Preloader';
// import CustomModal from '../molecules/CustomModal';

function Mechanic() {
  const { width } = useWidth()
  const { context } = useContext(StateController);

  if (!context || !context.user) {
    return <Preloader type="section" invert />;
  }


  return (
    <div className='mc-page mechanic slow_ani'>

      {/* <CustomModal
        text={'¡Gracias por participar!'}
        image={'/gracias.png'}
        // title={'¡Gracias por participar!'}
        desc={'Como viste tú y tu Tarjeta Mastercard hacen el mejor Match. Prepárate, porque viene mucho más.'}
        cta={'Aceptar'}
        primaryAction={
          {
            buttonText: 'Cerrar',
            loading: false
          }
        }
        setShowModal={true}
      /> */}

      {width > 992 &&
        <PrimaryBanner
          image="banner_mechanic_desktop.png"
        />
      }

      <Container>
        <h1 className='informationTextTitle'>Así puedes ganar</h1>
        <p className='informationTextParSmall'>Conoce qué hacer para aprovechar tus compras, cumplir tu meta y ganar.</p>

      </Container>
      {context &&
        <Container>
          <div className="container-card-mechanic">
            <CardBox
              image={"swiper2.png"}
              title={"1. Usa tu tarjeta para todo"}
              desc={"Tendrás dos oportunidades para ganar. Te asignaremos una meta mensual que podrás cumplir mientras usas tu Tarjeta de Crédito Credencial - Mastercard."}
            />
            <br />
            <CardBox
              image={"swiper3.png"}
              title={"2. Gana y sigue ganando"}
              desc={"Alcanza tu meta mensual y podrás ganar hasta 2 bonos en la vigencia de la campaña."}
            />
            <br />
            <CardBox
              image={"swiper4.png"}
              title={"3. La competencia sigue"}
              desc={`Cumple tu meta tantas veces como sea posible y participa por un bono de $80.000.000 para la compra de un carro nuevo.`}
            />
          </div>
        </Container>
      }

    </div>
  )
}
export default Mechanic