import React from 'react'
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive';

import Container from '../Container/Container'
import { sendDataLayer } from '../../utils/functions'

function Footer({
  footerType,
  footerCardsImg,
  secondaryFooterImg,
  links,
  linksText,
  content,
  bankFooterImg,
  copyright
}) {

  const addDatalayerFooter = (e) => {
    if (e.target.closest('a')) {
      let text = e.target.closest('a').innerText

      if (text == '') {
        text = e.target.closest('a').getAttribute('data-text')
      }
      
      let link = e.target.closest('a').getAttribute('href')
      sendDataLayer({ datalayer: { event: 'footer', text: text, link: link }, repeat: true })
    }
  }
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  return (
    <div className={`mc-footer-container mc-footer-container-type-${footerType}`}>
      {footerType === 1 && footerCardsImg &&
        <img src={`/assets/images/${footerCardsImg}`} alt="Footer Cards" className='mc-footer-container__cards' />
      }
      {footerType === 1 &&
        <div className='mc-footer-container__bg'>
          <Container>
            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      <Link
                        onClick={addDatalayerFooter}
                        key={index}
                        to={link.link}
                      >{link.text}</Link>
                    )
                  })}
                </ul>
              }
              {linksText && <p>{linksText}</p>}
              {secondaryFooterImg &&
                <img
                  src={`/assets/images/${secondaryFooterImg}`}
                  alt="Footer"
                  className='mc-footer-container__image'
                />
              }
            </div>
          </Container>
          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>
        </div>
      }

      {footerType === 1 &&
        <div className='mc-footer-container__subbg'>
          <Container>
            <img src={`/assets/images/${bankFooterImg}`} alt="Banco Footer" />
          </Container>
        </div>
      }

      {footerType === 2 && bankFooterImg &&
        <div className='mc-footer-container__bg'>
          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className='mc-footer-container__bg--image'
            />
            <div className='mc-footer-container__sections'>
              <div className='mc-footer-container__sections--section'>
                <h4>Información:</h4>
                {links && links[0] &&
                  <ul className='mc-footer-container__section--links'>
                    {links.map((link, index) => {
                      return (
                        <Link
                          key={index}
                          to={link.link}
                        >{link.text}</Link>
                      )
                    })}
                  </ul>
                }
              </div>
              <div className='mc-footer-container__sections--section'>
                <h4>Contacto:</h4>
                <p>xxxxxxxxxxxx</p>
              </div>
            </div>

            {secondaryFooterImg &&
              <img
                src={`/assets/images/${secondaryFooterImg}`}
                alt="Footer"
                className='mc-footer-container__image'
              />
            }
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>
        </div>
      }

      {footerType === 3 &&
        <div className='mc-footer-container__bg'>
          {footerCardsImg &&
            <img src={`/assets/images/${footerCardsImg}`} alt="Footer Cards" className='mc-footer-container__cards' />
          }

          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className='mc-footer-container__bg--image'
            />

            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      <Link
                        key={index}
                        to={link.link}
                      >{link.text}</Link>
                    )
                  })}
                </ul>
              }
              {secondaryFooterImg &&
                <img
                  src={`/assets/images/${secondaryFooterImg}`}
                  alt="Footer"
                  className='mc-footer-container__image'
                />
              }
            </div>
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>

          <Container>
            <div className='mc-footer-container__subbg'>
              {copyright}
            </div>
          </Container>

        </div>
      }

      {footerType === 4 &&
        <Container>
          <div className='mc-footer-container__section'>
            {links && links[0] &&
              <ul className='mc-footer-container__section--links'>
                {links.map((link, index) => {
                  return (
                    <Link
                      key={index}
                      to={link.link}
                    >{link.text}</Link>
                  )
                })}
              </ul>
            }
            {secondaryFooterImg &&
              <img
                src={`/assets/images/${secondaryFooterImg}`}
                alt="Footer"
                className='mc-footer-container__image'
              />
            }
          </div>
        </Container>
      }

      {footerType === 4 &&
        <Container className="mc-footer-container__text">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <img
            src="/assets/images/vigilated.png"
            className="mc-footer-vigilated" alt="Footer logos"
          />

          <img
            src={`/assets/images/${bankFooterImg}`}
            alt="Banco Footer"
            className='mc-footer-container__bg--image'
          />
        </Container>
      }

      {footerType === 5 &&
        <div className='mc-footer-container__bg'>
          <Container>
            <img
              src={`/assets/images/${bankFooterImg}`}
              alt="Banco Footer"
              className='mc-footer-container__bg--image'
            />
            <div className='mc-footer-container__sections'>
              <div className='mc-footer-container__sections--section'>
                <h4>Información:</h4>
                {links && links[0] &&
                  <ul className='mc-footer-container__section--links'>
                    {links.map((link, index) => {
                      return (
                        <Link
                          key={index}
                          to={link.link}
                        >{link.text}</Link>
                      )
                    })}
                  </ul>
                }
              </div>
              <div className='mc-footer-container__sections--section'>
                <h4>Contacto:</h4>
                <p>Bogotá: (601) 444 1777</p>
                <p>Cali: (602) 885 9595</p>
                <p>Medellín: (604) 325 6000</p>
                <p>Línea nacional: 01 8000 518 000</p>
              </div>
            </div>

            {secondaryFooterImg &&
              <img
                src={`/assets/images/${secondaryFooterImg}`}
                alt="Footer"
                className='mc-footer-container__image'
              />
            }
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>
        </div>
      }


      <>
        {footerType === 6 && (
          <>
            {!isDesktop && (
              <div className='mc-footer-container__bg'>
                <Container>
                  <div className='mc-footer-container__sections'>
                    <div className='mc-footer-container__sections--section'>
                      {links && links[0] && (
                        <ul className='mc-footer-container__section--links'>
                          {links.map((link, index) => (
                            <Link key={index} to={link.link}>
                              {link.text}
                            </Link>
                          ))}
                        </ul>
                      )}
                      {secondaryFooterImg && (
                        <img
                          src={`/assets/images/${secondaryFooterImg}`}
                          alt="Footer"
                          className='mc-footer-container__image'
                        />
                      )}
                    </div>
                  </div>
                </Container>
                <Container className="mc-footer-container__text">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Container>
                <Container>
                  <div className='social-media-container'>
                    <div>
                      <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Youtube" href='https://www.youtube.com/channel/UC7YOu9z6AfN8Hs9wEqKf5AQ'>
                        <img src={`/assets/images/yt.png`} alt="" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Instagram" href='https://www.instagram.com/bco_occidente/'>
                        <img src={`/assets/images/insta.png`} alt="" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Linkedin" href='https://www.linkedin.com/company/banco-de-occidente/posts/?feedView=all'>
                        <img src={`/assets/images/in.png`} alt="" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Facebook" href='https://web.facebook.com/BcoOccidente/?_rdc=1&_rdr'>
                        <img src={`/assets/images/fb.png`} alt="" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="X" href='https://x.com/Bco_Occidente'>
                        <img src={`/assets/images/x.png`} alt="" />
                      </a>
                    </div>
                  </div>
                </Container>
                <Container>
                  <div className='trasactional-zone-container'>
                    <div className='banca-container'>
                      <div className='banca-app'>
                        <img src={`/assets/images/banca_app_logo.png`} alt="" />
                        <p>Banco de<br />Occidente Móvil</p>
                      </div>
                      <div className='banca-web'>
                        <img src={`/assets/images/COMPUTADOR.png`} alt="" />
                        <p>Portal<br />Transaccional</p>
                      </div>
                    </div>
                    <div className='zona-transaccional'>
                      <p>Zona transaccional</p>
                    </div>
                  </div>
                </Container>
                <div className='footer-six'>
                  <img src="/assets/images/vigilado_co.png" alt="Footer logos" className='vigilado-img' />
                  <div>
                    <img className="img-uno" src="/assets/images/Logo_sloganOcc.png" alt="Footer logos" />
                    <img className="img-dos" src="/assets/images/LogoMC.png" alt="Footer logos" />
                    <p>Todos los derechos reservados</p>
                  </div>
                </div>
              </div>
            )}
            {isDesktop ? (
              <div className='footer-desktop'>
                <Container>
                  <div className='mc-footer-container__sections'>
                    <div className='mc-footer-container__sections--section'>
                      {secondaryFooterImg && (
                        <img
                          src={`/assets/images/${secondaryFooterImg}`}
                          alt="Footer"
                          className='mc-footer-container__image'
                        />
                      )}
                    </div>
                  </div>
                </Container>
                <div className='footer-desktop-text-container'>
                  <div className='textFooter'>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                  <div>
                    <div>
                      {links && links[0] && (
                        <ul className='mc-footer-container__section--links'>
                          {links.map((link, index) => (
                            <Link
                              onClick={addDatalayerFooter}
                              key={index}
                              to={link.link}>
                              {link.text}
                            </Link>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div>
                      <div className='social-media-container' style={{ cursor: 'auto' }}>
                        <div>
                          <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Youtube" href='https://www.youtube.com/channel/UC7YOu9z6AfN8Hs9wEqKf5AQ'>
                            <img src={`/assets/images/yt.png`} alt="" />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Instagram" href='https://www.instagram.com/bco_occidente/'>
                            <img src={`/assets/images/insta.png`} alt="" />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Linkedin" href='https://www.linkedin.com/company/banco-de-occidente/posts/?feedView=all'>
                            <img src={`/assets/images/in.png`} alt="" />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="Facebook" href='https://web.facebook.com/BcoOccidente/?_rdc=1&_rdr'>
                            <img src={`/assets/images/fb.png`} alt="" />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" rel="noreferrer" onClick={addDatalayerFooter} data-text="X" href='https://x.com/Bco_Occidente'>
                            <img src={`/assets/images/x.png`} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='footer-six'>
                  <img src="/assets/images/vigilado_co.png" alt="Footer logos" className='vigilado-img' />
                  <div className='logo-container-footer'>
                    <div>
                      <img className="img-uno" src="/assets/images/Logo_sloganOcc.png" alt="Footer logos" />
                    </div>
                    <div>
                      <img className="img-dos" src="/assets/images/LogoMC.png" alt="Footer logos" />
                    </div>
                  </div>
                  <p className='textParFooter'>Todos los derechos reservados</p>
                </div>
              </div>
            ) : null}
          </>
        )}
      </>
    </div>
  )
}

export default Footer