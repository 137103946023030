import React from 'react';

function ProgressBar({
  title,
  percent,
  middle,
  dashboard,
  label,
  isGoalMet
}) {
  return (
    <div className={`mc-progressBar-container ${title ? 'has-title' : ''} ${middle ? 'col-2' : 'col-1'}`}>
      {title && (
        <div className='mc-progressBar-title'>
          {title}
        </div>
      )}
      {label && (
        <span className='mc-progressBar-details'>
          {label}
        </span>
      )}
      {dashboard && (
        <span className='mc-progressBar-label'>{`${percent}%`} {dashboard}</span>
      )}
      <div className="mc-progressBar-progress" style={{ "width": `${percent > 100 ? 100 : percent}%` }}></div>
      <div className='mc-progressBar-bubble' style={{ "left": `${percent >= 100 ? 96 : percent}%` }}>
        <img src={isGoalMet ? "/assets/images/progress_icon_two.png" : "/assets/images/progress_icon.png"} />
      </div>
    </div>
  );
}

export default ProgressBar;
