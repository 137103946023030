import React, { useState, useContext } from 'react';
import { StateController } from './../../App'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import AwardBox from '../molecules/AwardBox';
import Button from '../atoms/Button';
import CustomModal from '../molecules/CustomModal';
import { sendDataLayer }  from '../../utils/functions'
import useWidth from '../../hooks/useWidth';

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  showStock,
  download,
  twoStepAuth,

}) {
  const {
    // setAwardSelected,
    awardSelected
    // handlerRootModal,
    // context
  } = useContext(StateController)
  console.log(awardSelected);
  const [showTermsModal, setShowTermsModal] = useState(false);
  console.log(showTermsModal, "Premio");
  const { width } = useWidth()
  const pagerNumber = width > 992 ? 21 : 5;
 
  // const [awardSelected, setAwardSelected] = useState({})
  const settings = {
    dots: list.length > pagerNumber,
    // dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: list.length > pagerNumber,
    // arrows: false,
    appendDots: dots => (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {dots}
      </div>
    ),
    customPaging: (i, isActive) => (
      <div className={isActive ? 'active-dot' : 'inactive-dot'}>
        {i + 1}
      </div>
    )
  };

  const mobileItems = [];
  for (let i = 0; i < list.length; i += pagerNumber) {
    mobileItems.push(list.slice(i, i + pagerNumber));
  }

  

  const CustomPrevArrow = ({ onClick }) => (
    <button className='arrow-prev-awards' onClick={onClick} style={{ position: 'absolute', top: '100.5%', left: '35%', zIndex: 1, border: 'none', background: 'none', fontSize: '20px', color: '#364773' }}>
      &lt;
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button className='arrow-next-awards' onClick={onClick} style={{ position: 'absolute', bottom: '-6.5%', right: '35%', zIndex: 1, border: 'none', background: 'none', fontSize: '20px', color: '#364773' }}>
      &gt;
    </button>
  );

  const layer = () => {
    sendDataLayer({datalayer: { event: 'progress', text: 'Ver todos los comercios disponibles', link: '/premios' },repeat: false})
  }

  const layerProgress = () => {
    sendDataLayer({datalayer: { event: 'redemption_interaction', text: 'Ver progreso', link: '/progreso' },repeat: false})
  }
  const layerPremios = () => {
    sendDataLayer({datalayer: { event: 'redemption_interaction', text: 'Ver bonos', link: '/premios' },repeat: false})
  }


  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {showTermsModal &&
        <CustomModal
          // title={showTermsModal?.name}
          text={showTermsModal?.name}
          desc={showTermsModal?.description}
          // terms={}
          image={showTermsModal?.image}
          cta="Aceptar"
          icon={true}
          show={showTermsModal}
          setShowModal={setShowTermsModal}
        />}


      {list && list.length > 0 ? (
        <div className="mc-awards-container__items">
          <Slider {...settings} prevArrow={<CustomPrevArrow />} nextArrow={<CustomNextArrow />}>
            {mobileItems.map((items, pageIndex) => (
              <div key={pageIndex} className="mc-awards-container__items">
                {items.map((item, index) => (
                  <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                    <AwardBox
                      type={awardBoxType}
                      award={item}
                      canRedem={canRedem}
                      termLink={termLink}
                      termText={termText}
                      termModal={termModal}
                      redemtionText={redemtionText}
                      showStock={showStock}
                      download={download}
                      twoStepAuth={twoStepAuth}
                      setShowTermsModal={setShowTermsModal}
                      position={index}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="container-no-redemptions">
          <div>
            <p><strong>Aún no has redimido bonos</strong><br />Completa tus metas y empieza a redimir.</p>
          </div>
          <div className="container-btn-redentions">
            <div className="btn-progress" onClick={layerProgress}>
              <Button
                type="primary"
                status={'active'}
                link={'/progreso'}
                text={"Ver progreso"}
                externalButton={true}
              />
            </div>
            <div className="btn-awards" onClick={layerPremios}>
              <Button
                type="secondary"
                status={'active'}
                link={'/premios'}
                text={"Ver bonos >"}
                externalButton={true}
              />
            </div>
          </div>


        </div>
      )}

      {limit && limitButtonText && list && list.length > 0 && (
        <div className="mc-awards-container__actions" onClick={layer}> 
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      )}
    </div>
  );
}

export default AwardsGallery;
