import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Chart } from "react-google-charts"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

import {
  gtDashboard
} from '../../utils/helpers'
import { getPrice, getPercent, getEnv } from '../../utils/functions'

import Container from '../../layouts/Container/Container'
import AlertMessage from '../atoms/AlertMessage'
import DashboardModule from '../organisms/DashboardModule'
import ProgressBar from '../atoms/ProgressBar'
import Input from '../atoms/Input'
import Button from '../atoms/Button'
import Preloader from '../atoms/Preloader'


function Dashboard() {
  const [searchParams] = useSearchParams()
  const [loginsLoading, setLoginsLoading] = useState(false)
  const [loginsUniqueLoading, setLoginsUniqueLoading] = useState(false)
  const [redemtionsLoading, setRedemtionsLoading] = useState(false)
  const [redemtionsUniqueLoading, setRedemtionsUniqueLoading] = useState(false)
  const [awardsLoading, setAwardsLoading] = useState(false)
  const [redemtionsData, setRedemtionsData] = useState([])

  const [accessCode, setAccessCode] = useState('')
  const [dashboardData, setDashboardData] = useState(false)
  const [dashboardAwardDenominations, setDashboardAwardDenominations] = useState([])
  const [dashboardDataError, setDashboardDataError] = useState(false)

  const [processOk, setprocessOk] = useState(false)
  const [processError, setprocessError] = useState(false)
  // const [percentageSpent, setPercentageSpent] = useState(0);

  // const [loginsSuccessData, setLoginsSuccessData] = useState([])
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
  const fileExtension = '.xlsx';

  if (redemtionsData === 'none') {
    setRedemtionsLoading()
    setRedemtionsUniqueLoading()
    setAwardsLoading()
  }

  const loginsReport = async () => {
    setLoginsLoading(true)
    if (dashboardData && dashboardData.reportLogins) {
      const ws = XLSX.utils.json_to_sheet(dashboardData.reportLogins)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const datas = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(datas, 'ReportLogins' + fileExtension)
      setLoginsLoading(false)
    } else {
      alert("Error, data incompleta.")
      setLoginsLoading(false)
    }
  }
  const loginsReportUnique = async () => {
    setLoginsUniqueLoading(true)
    console.log(dashboardData, "esta es la dashboard");
    if (dashboardData && dashboardData.reportLoginsUnique) {
      const ws = XLSX.utils.json_to_sheet(dashboardData.reportLoginsUnique)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const datas = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(datas, 'ReportLoginsUnique' + fileExtension)
      setLoginsUniqueLoading(false)
    } else {
      alert("Error, data incompleta.")
      setLoginsUniqueLoading(false)
    }

  }
  const redemtionsReport = async () => {
    setRedemtionsLoading(true)
    if (dashboardData && dashboardData.reportRedemtions) {
      const ws = XLSX.utils.json_to_sheet(dashboardData.reportRedemtions)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const datas = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(datas, 'ReportRedemtions' + fileExtension)
      setRedemtionsLoading(false)
    } else {
      alert("No hay redenciones.")
      setRedemtionsLoading(false)
    }

  }
  const redemtionsReportUnique = async () => {
    setRedemtionsUniqueLoading(true)
    if (dashboardData && dashboardData.reportRedemtionsUnique) {
      const ws = XLSX.utils.json_to_sheet(dashboardData.reportRedemtionsUnique)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const datas = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(datas, 'ReportRedemtionsUnique' + fileExtension)
      setRedemtionsUniqueLoading(false)
    } else {
      alert("No hay redenciones.")
      setRedemtionsUniqueLoading(false)
    }

  }
  const awardsReport = async () => {
    // setAwardsLoading(true)
    alert("Módulo en desarrollo. Intenta de nuevo más adelante.")

  }

  const handleChangeAccessCode = async (props) => {

    if (props.target.value.length < 11) {
      setAccessCode(props.target.value)
    }
  }
  const handleAccess = async () => {
    window.location.href = `/dashboard?secret=${accessCode}`
  }

  const loginsSuccess = [
    ["Día", "Exitosos", "No Exitosos", "Autologin"],
    ["", 0, 0, 0]
  ]



  useEffect(() => {

    if (searchParams.get('secret') !== '') {

      gtDashboard(searchParams.get('secret')).then((data) => {

        console.log("Dashboard data:", data)

        if (data?.campaing?.data) {
          console.log(data.campaing.data)
          setDashboardData(data.campaing.data)

          // const presupuestoTotal = data.campaing.data.budget.api;
          // const totalGastado = data.campaing.data.budget.api_redeemed;
          // const porcentajeGastado = (totalGastado / presupuestoTotal) * 100;
          // const percentageSpent = (100 - porcentajeGastado).toFixed(2);
          // setPercentageSpent(percentageSpent);
          // console.log(percentageSpent), "aca esta lo gastado";

          if (data.campaing.data.awards && data.campaing.data.awards[0]) {
            const obj = data.campaing.data.awards[0]
            const properties = Object.getOwnPropertyNames(obj)
            const denominations = []
            properties.forEach(property => {
              if (property.charAt(0) === 's' && property.length >= 1 && property.length <= 5) {
                denominations.push(property)
              }
            })
            setDashboardAwardDenominations(denominations)
          }


          if (data.campaing.data && data.campaing.data.reportRedemptionsPercents && data.campaing.data.reportRedemptionsPercents[0]) {
            const arr = [
              ["Comercio", "Redenciones"]
            ]
            data.campaing.data.reportRedemptionsPercents.forEach(redemtions => {

              arr.push([`${redemtions.name}`, redemtions.times])
            })
            setRedemtionsData(arr)
          }

        } else {

          if (data.message && data.message === 'Unauthorized!') {
            setDashboardDataError('unauthorized')
          } else {
            setDashboardDataError('error')
          }

        }
      })
    }
  }, [])

  useEffect(() => {
    if (processOk) setTimeout(() => setprocessOk(false), 2500)
  }, [processOk])

  useEffect(() => {
    if (processError) setTimeout(() => setprocessError(false), 2500)
  }, [processError])


  return (
    <div className='mc-page dashboard slow_ani'>
      {processOk && <Preloader type="ok" />}
      {processError && <Preloader type="error" />}

      <Container>
        {searchParams.get('secret') && !dashboardDataError
          ? <>
            <DashboardModule
              title="Información general"
              col={1}
            >
              {dashboardData
                ? <>
                  <ProgressBar
                    title="Usuarios registrados"
                    percent={100}
                    label={`${getPrice(dashboardData.usersCount, true)}`}
                  />
                  <ProgressBar
                    title="Total logins"
                    percent={100}
                    label={`${getPrice(dashboardData.loginsCount, true)}`}
                    middle
                  />
                  <ProgressBar
                    title="Total logins exitosos"
                    percent={getPercent(dashboardData.loginsCount, dashboardData.successLoginsCount, true)}
                    label={`${getPrice(dashboardData.successLoginsCount, true)}`}
                    middle
                    dashboard="del total de logins."
                  />
                  <ProgressBar
                    title="Total ganadores"
                    percent={getPercent(dashboardData.usersCount, dashboardData.winnersCount, true)}
                    label={`${getPrice(dashboardData.winnersCount, true)}`}
                    middle
                    dashboard="del todal de usuarios registrados."
                  />
                  <ProgressBar
                    title="Total redenciones"
                    percent={getPercent(dashboardData.winnersCount, dashboardData.redemptionsCount, true)}
                    label={`${getPrice(dashboardData.redemptionsCount, true)}`}
                    middle
                  />
                  <ProgressBar
                    title="Total redimido"
                    percent={getPercent((Number(dashboardData.budget.api) + Number(dashboardData.budget.prepurchase)), dashboardData.redemptionsAmount, true)}
                    label={`${getPrice(Number(dashboardData.redemptionsAmount))}`}
                    dashboard={`del presupuesto total (${getPrice((Number(dashboardData.budget.api) + Number(dashboardData.budget.prepurchase)), true)}).`}
                  />
                  <h5>Estado de credenciales:</h5>
                  <table className='table'>
                    <tr>
                      <th className='head_hight'>Entorno</th>
                      <th>Quantum API</th>
                      <th>Base de datos</th>
                      <th>Estado</th>
                    </tr>
                    <tr>
                      <td className='head_hight'>{getEnv()}</td>
                      <td>{getEnv()}</td>
                      <td>{getEnv()}</td>
                      <td>
                        <span className={`table-enable`}>
                          Credenciales correctas
                        </span>
                      </td>
                    </tr>
                  </table>
                </>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>

            <DashboardModule
              title="Logins"
              col={2}
              reportAction={{
                text: "Reporte completo",
                onClick: loginsReport,
                loading: loginsLoading
              }}
              reportSecondAction={{
                text: "Reporte únicos",
                onClick: loginsReportUnique,
                loading: loginsUniqueLoading
              }}
            >
              {dashboardData
                ? <Chart
                  chartType="Line"
                  width="100%"
                  height="280px"
                  data={loginsSuccess}
                  options={{
                    chart: {
                      title: "Comportamiento del proceso de autenticación",
                      subtitle: "Logins exitosos por día."
                    }
                  }}
                />
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>
            <DashboardModule
              title="Redenciones"
              col={2}
              reportAction={{
                text: "Reporte completo",
                onClick: redemtionsReport,
                loading: redemtionsLoading
              }}
              reportSecondAction={{
                text: "Reporte únicos",
                onClick: redemtionsReportUnique,
                loading: redemtionsUniqueLoading
              }}
            >
              {dashboardData
                ? <Chart
                  chartType="PieChart"
                  data={redemtionsData}
                  options={{
                    title: "Comercios redimidos.",
                  }}
                  width={"100%"}
                  height={"280px"}
                />
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>

            <DashboardModule
              title="Cumplimiento de campaña"
              col={1}
            >
              {dashboardData
                ? <>
                  <ProgressBar
                    title="Meta cumplida"
                    percent={getPercent(dashboardData.usersCount, dashboardData.goal_achievement.goal_met, true)}
                    label={getPrice(dashboardData.goal_achievement.goal_met, true)}
                    dashboard="de los clientes han cumplido la meta."
                    middle
                  />
                  <ProgressBar
                    title="Meta sobrecumplida"
                    percent={getPercent(dashboardData.usersCount, dashboardData.goal_achievement.goal_over_met, true)}
                    label={getPrice(dashboardData.goal_achievement.goal_over_met, true)}
                    middle
                    dashboard="de los clientes han superado la meta."
                  />
                  <ProgressBar
                    title="Meta no cumplida"
                    percent={getPercent(dashboardData.usersCount, dashboardData.goal_achievement.goal_not_met, true)}
                    label={getPrice(dashboardData.goal_achievement.goal_not_met, true)}
                    middle
                    dashboard="de los clientes no han alcanzado su meta."
                  />
                  <ProgressBar
                    title="No participantes"
                    percent={getPercent(dashboardData.usersCount, dashboardData.goal_achievement.no_process, true)}
                    label={getPrice(dashboardData.goal_achievement.no_process, true)}
                    middle
                    dashboard="de los clientes no ha participado."
                  />
                </>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>
            <DashboardModule
              title="Presupuestos"
              col={1}
            >
              {dashboardData
                ? <>
                  <h5 style={{ marginBottom: 5, marginTop: 0 }}>Presupuesto API Quantum:</h5>
                  <p style={{ marginTop: 0 }}>{getPrice(dashboardData.budget.api)} ({getPrice(dashboardData.budget.api_redeemed)}) - Queda un <strong>{getPercent(dashboardData.budget.api, dashboardData.budget.api_redeemed, false)}</strong></p>

                  <h5 style={{ marginBottom: 5 }}>Presupuesto Precompra (Archivos PDF):</h5>
                  <p style={{ marginTop: 0 }}>{getPrice(dashboardData.budget.prepurchase)} ({getPrice(dashboardData.budget.prepurchase_redeemed)}) - Queda un <strong>{getPercent(dashboardData.budget.prepurchase, dashboardData.budget.prepurchase_redeemed, false)}</strong></p>

                  <h5 style={{ marginBottom: 5 }}>Presupuesto Total:</h5>
                  <p style={{ marginTop: 0 }}>{getPrice((Number(dashboardData.budget.api) + Number(dashboardData.budget.prepurchase)), true)} ({getPrice(dashboardData.budget.api_redeemed + dashboardData.budget.prepurchase_redeemed)}) - Queda un <strong>{getPercent((dashboardData.budget.prepurchase + dashboardData.budget.api), (dashboardData.budget.prepurchase_redeemed + dashboardData.budget.api_redeemed), false)}</strong></p>
                </>
                : <Preloader
                  type="section"
                  invert
                />
              }


            </DashboardModule>

            <DashboardModule
              title="Disponibilidad de bonos"
              reportAction={{
                text: "Descargar reporte",
                onClick: awardsReport,
                loading: awardsLoading
              }}
            >
              {dashboardData
                ? <table className='table'>
                  <tr>
                    <th>Comercio</th>
                    <th>Tipo</th>
                    {dashboardAwardDenominations.map((denomination, index) => {
                      return (
                        <th key={index}>{denomination.replace('s', '$')}.000</th>
                      )
                    })}
                  </tr>

                  {dashboardData.awards.map((award, index) => {
                    return (
                      <tr key={index}>
                        <td>{award.name}</td>
                        <td>{award.pre_purchased ? 'Precompra' : 'API - Quantum'}</td>

                        {dashboardAwardDenominations.map((denomination, index) => {
                          const data = award[denomination]
                          let available = 'stock'
                          let qty = ''

                          if (data === 0) available = 'none'
                          if (award.status === 0) available = 'disabled'
                          if ((award.pre_purchased === 0 || award.pre_purchased === null) && data > 0) {
                            qty = data

                            if (data === 1) qty = ''
                          }
                          if (award.pre_purchased > 0) qty = data
                          if (award.pre_purchased > 0 && data > 1) qty = qty - 1

                          return (
                            <td key={index}>
                              <span className={`table-${available === 'stock' ? 'enable' : available === 'none' ? 'unabled' : 'inactive'}`}>
                                {available === 'stock' ? `${qty === '' ? 'D' : `${qty} d`}isponible${qty > 1 ? 's' : ''}` : available === 'none' ? 'No dispo' : 'Desactivado'}
                              </span>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </table>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>

            <DashboardModule
              title="Tabla de alianzas"
            >
              {dashboardData
                ? <table className='table'>
                  <tr>
                    <th>Aliado</th>
                    <th>Regla</th>
                    <th>Programado</th>
                    <th>Estado</th>
                    <th>Visible hoy</th>
                  </tr>

                  {dashboardData.alliances.map((alliance, index) => {
                    let visible = false

                    if (alliance.activate) {
                      if (alliance.activate.includes('/')) {
                        const timer = new Date(alliance.activate)
                        const today = new Date()
                        if (today.toDateString() === timer.toDateString()) visible = true

                      } else if (alliance.activate !== '') {
                        const today = new Date()
                        const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
                        const todayDay = days[today.getDay()]
                        const enableDays = alliance.activate.split(',')
                        let returner = false
                        for (let i = 0; i < enableDays.length; i++) {
                          if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
                        }
                        visible = returner
                      }
                    } else {
                      visible = true
                    }

                    return (
                      <tr key={index}>
                        <td>{alliance.name}</td>
                        <td>{alliance.rule} OFF</td>
                        <td>
                          {
                            (alliance.activate === null)
                              ? 'Activa siempre'
                              : alliance.activate.includes('/')
                                ? `Activa el ${alliance.activate}`
                                : `Activa solo los ${alliance.activate}`
                          }
                        </td>
                        <td>
                          <span className={`table-${alliance.status === 1 ? 'enable' : 'unabled'}`}>
                            {alliance.status === 1 ? 'Activa' : 'Inactiva'}
                          </span>
                        </td>
                        <td>
                          <span className={`table-${visible ? 'enable' : 'unabled'}`}>
                            {visible ? 'SI' : 'NO'}
                          </span>
                        </td>
                      </tr>
                    )
                  })}

                </table>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>

            <DashboardModule
              title="IPs Sospechosas"
            >
              {dashboardData
                ? <table className='table'>
                  <tr>
                    <th>IP</th>
                    <th>Conteo de IDmasks</th>
                    <th>Estado</th>
                  </tr>
                </table>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>

            <DashboardModule
              title="Alertas"
            >
              {dashboardData
                ? <table className='table table-min'>
                  <tr>
                    <th>Alarma</th>
                    <th>Disparador</th>
                    <th>Estado</th>
                  </tr>
                  <tr>
                    <td>Problemas con Quantum</td>
                    <td>Cuando un usuario intenta redimir pero la API Quantum responde con error.</td>
                    <td>
                      <span className='table-unabled'>Inactiva</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Presupuesto</td>
                    <td>Cuando el presupuesto supera las franjas de porcentaje 10, 20, 30, 40, 50, 60, 70, 80 y 90%.</td>
                    <td>
                      <span className='table-unabled'>Inactiva</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Precompra Agotado</td>
                    <td>Cuando un bono precompra se encuentra agotado.</td>
                    <td>
                      <span className='table-unabled'>Inactiva</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Problemas en el servicio de login.</td>
                    <td>Cuando se acumulan más de 10 intentos de login fallidos seguidos.</td>
                    <td>
                      <span className='table-unabled'>Inactiva</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Problemas en el servicio de redención y descarga.</td>
                    <td>Cuando se acumulan más de 10 intentos de redención fallidos consecutivos.</td>
                    <td>
                      <span className='table-unabled'>Inactiva</span>
                    </td>
                  </tr>
                </table>
                : <Preloader
                  type="section"
                  invert
                />
              }
            </DashboardModule>
          </>
          :
          <AlertMessage
            type="error"
            title={`${dashboardDataError === 'error' ? 'Ocurrió un error' : 'Acceso no autorizado'}`}
            message={`${dashboardDataError === 'error' ? 'No logramos obtener la data necesaria para generar los reportes. Intenta de nuevo o comunicate con el administrador del portal.' : 'Por favor ingresa el código de acceso a este contenido.'}`}
            blocked
          >
            {dashboardDataError === 'unauthorized' &&
              <div className='custom-forms'>
                <Input
                  type="code"
                  label="Código de acceso"
                  placeholder=""
                  onChange={handleChangeAccessCode}
                  inputByCharacter={false}
                  maxlength={10}
                  value={accessCode}
                  error={false}
                />
                <Button
                  type="primary"
                  text="Acceder"
                  loading={false}
                  status="active"
                  onClick={handleAccess}
                />
              </div>
            }

          </AlertMessage>
        }
      </Container>
    </div>
  )
}
export default Dashboard