import React, { useContext, useState } from 'react';
import { StateController } from './../../App';
import Container from '../../layouts/Container/Container';
import ProgressBar from '../atoms/ProgressBar';
import GoalCounter from '../atoms/GoalCounter';
import Button from '../atoms/Button';
import PrimaryBanner from '../molecules/PrimaryBanner';
import Preloader from '../atoms/Preloader';
import useWidth from '../../hooks/useWidth';
import {getPrice, sendDataLayer } from '../../utils/functions';

function Progress() {
  const { context } = useContext(StateController);
  const [showToolTip, setShowToolTip] = useState(false);

  const { width } = useWidth();
  const { tracing, user, stage } = context;
  console.log(tracing, "tracing", user, "user", stage, "stage");
  // console.log(context, "este es mi context");

  if (!context || !context.user) {
    // setShowToolTip(true)
    return <Preloader type="section" invert />;
    
  }

  const layer = () => {
    sendDataLayer({ datalayer: { event: 'progress', text: 'Redimir premio >', link: '/premios' }, repeat: true });
  };

  const layerTerms = () => {
    sendDataLayer({datalayer: { event: 'progress', text: 'Ver términos y condiciones', link: '/terminos-y-condiciones' },repeat: true})
  }

  const isGoalMet1 = tracing ? tracing.amount_1 >= user.goal_amount_1 : false;
  const isGoalMet2 = tracing ? tracing.amount_2 >= user.goal_amount_2 : false;

  const progressPercent1 = tracing ? (tracing.amount_1 / user.goal_amount_1) * 100 : 0;
  const progressPercent2 = tracing ? (tracing.amount_2 / user.goal_amount_2) * 100 : 0;

  const closeToolTip = () => {
    setShowToolTip(false)
  }

  return (
    <div className={`mc-page progress slow_ani ${showToolTip ? 'is-tooltip' : ''}`}>

    {showToolTip && 
        <div className='big-tooltip'>
          <Container>
            Tienes un bono pendiente por redimir. ¡Disfrútalo ya y sigue avanzando!
            <button onClick={closeToolTip}>
              <img src='/assets/images/close-icon.svg' />
            </button>
          </Container>
        </div>
      }

      {width > 992 && (
        <PrimaryBanner
          image="banner_mechanic_desktop.png"
        />
      )}
      <Container>
        <h1 className='informationTextTitle'>Tu progreso</h1>
        <p className='informationTextParSmall' style={{ maxWidth: '688px', width: 'auto', margin: '0 auto' }}>
          Cada vez que usas tu Tarjeta de Crédito Credencial Mastercard, te da la oportunidad de convertir tus compras en premios.
        </p>

        <div className='mc-progress__info'>
          <div className={`mc-progress__info--card ${isGoalMet1 ? 'green_class' : 'blue_class'} ${progressPercent1 == 0 ? 'noProgress': ''}`}>
            <h2>Meta abril</h2>
            <p>Cumple tu meta antes del 30 de abril</p>
            <div className='mc-progress__info--card__label'>
              Llevas {tracing ? getPrice(tracing.amount_1) : '$0'}
              <span>de {getPrice(user.goal_amount_1)}</span>
            </div>
            <span className='dateTexts'>
              Se registraron las compras que realizaste desde el DD/MM/AAAA hasta el DD/MM/AAAA.
            </span>
            <ProgressBar
              percent={progressPercent1}
              isGoalMet={isGoalMet1}
            />
            {context?.tracing?.winner_1 === 1 ? (
              <div onClick={layer}>
                <Button
                  type="primary"
                  text={'Redimir premio >'}
                  loading={false}
                  status={stage === 2 ? 'inactive' : 'active'}
                  link="/premios"
                />
              </div>
            ) : (
              <div>
                <Button
                  imgl={'lock.png'}
                  type="primary"
                  text={'Redimir premio'}
                  loading={false}
                  status={'inactive'}
                  link="/premios"
                />
              </div>
            )}
          </div>

          <div className={`mc-progress__info--card ${isGoalMet2 ? 'green_class' : 'blue_class'} ${context.block == 1 && progressPercent1 < 100 ? 'noActive' : ''}`}>
            <h2>Meta mayo</h2>
            <p>Cumple tu meta antes del 31 de mayo</p>
            <div className='mc-progress__info--card__label'>
              Llevas {tracing ? getPrice(tracing.amount_2) : '$0'}
              <span>de {getPrice(user.goal_amount_2)}</span>
            </div>
            <ProgressBar
              percent={progressPercent2}
              isGoalMet={isGoalMet2}
            />
            {context?.tracing?.winner_2 === 1 ? (
              <div onClick={layer}>
                <Button
                  type="primary"
                  text={'Redimir premio >'}
                  loading={false}
                  status={'active'}
                  link="/premios"
                />
              </div>
            ) : (
              <div>
                <Button
                  imgl={'lock.png'}
                  type="primary"
                  text={'Redimir premio'}
                  loading={false}
                  status={'inactive'}
                  link="/premios"
                />
              </div>
            )}
          </div>
        </div>

        <GoalCounter
          label={`Has cumplido tu meta:`}
        />

        <div className="banner-major-award">
          <a href="/terminos-y-condiciones" target='_blank' onClick={layerTerms}></a>
          <img src={`/assets/images/banner-premio-mayor${width < 992 ? '-m' : ''}.svg`} />
        </div>

        
      </Container>

    </div>
  );
}

export default Progress;
