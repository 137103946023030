import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import PrimaryBanner from '../molecules/PrimaryBanner'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'

import { getMaximumBlock } from '../../utils/functions'
// import PopUp from '../molecules/PopUp'
import useWidth from '../../hooks/useWidth'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const { context } = useContext(StateController)
  const { width } = useWidth()



  useEffect(() => {

    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
    }

    console.log("Awards:", context)
  }, [context])

  console.log("canRedem", canRedem)

  // const message = `A hoy hemos entregado el <strong>60.7%</strong> de bonos.<br/> <strong>¡No esperes más y elige el tuyo!<strong/>`;
  return (
    <div className='mc-page awards slow_ani'>
      {/* <PopUp
        message={message}
      /> */}

      {width > 992 &&
        <div className='desktop-hero'>
          <div className='desktop-hero-labels'>
            <Container fullHeight>
              <VerticalCenter>
                <h1 className='informationTextTitle'>
                  Bonos que <br /> puedes ganar
                </h1>
                <p className="informationTextPar">
                  Cumpliendo cada una de tus metas<br />asignadas puedes canjear un bono.
                </p>
              </VerticalCenter>
            </Container>
          </div>
          <PrimaryBanner
            image="banner_awards_desktop.png"
          />
        </div>
      }

      <div className='containerAward'>
        <Container>
          {width < 992 &&
            <>
              <h1 className='informationTextTitle'>
                Bonos que puedes ganar
              </h1>
              <p className="informationTextPar">
                Cumpliendo tu meta asignada <br />
                puedes canjear un bono.
              </p>
            </>
          }
          {awards
            ? <AwardsGallery
              awardBoxType={4}
              itemsPerLine={3}
              limit={false}
              limitButtonText="Ver más comercios"
              showStock={true}
              list={awards}
              canRedem={canRedem}
              twoStepAuth={false}
              redemtionText="Redimir >"
              termText="Términos y condiciones"
              termLink="/terminos-y-condiciones"
            />
            : <Preloader
              type="section"
              invert
            />

          }

        </Container>
      </div>
    </div>
  )
}
export default Awards