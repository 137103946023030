import React, { useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import CustomModal from './CustomModal'
import Button from '../atoms/Button'
// import Tyc from '../atoms/Tyc'
// import Tag from '../atoms/Tag'

import { getPrice, sendDataLayer } from '../../utils/functions'
import { doloadRedeem } from '../../utils/helpers'
import { StateController } from './../../App'
import { useLocation } from 'react-router-dom';


function AwardBox({
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  // showStock,
  token,
  download,
  twoStepAuth,
  position
}) {
  const {
    setAwardSelected,
    handlerRootModal,
    context
  } = useContext(StateController)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  // const [tracing, setTracing] = useState(false)
  const location = useLocation();

  // const [showTermsModal, setShowTermsModal] = useState(false);

  // const title = "Quieres ganar un premio"
  const imgSrc = location.pathname === '/mis-redenciones' ? 'download.png' : null;

  useEffect(() => {
    if (context && context.tracing) {
      console.log("Context tracing:", context)
      // setTracing(context.tracing)
    }
  }, [context])

  const handlerModal = () => {
    if (!download) {
      sendDataLayer({ datalayer: { event: 'bond_interest', product_name: award.name, product_price: award.price, index: position }, repeat: true })
    }
    if (download && !downloadLoading) {
      console.log("Redencion:", award)
      setDownloadLoading(true)
      doloadRedeem(award.id, token).then((data) => {
        console.log("result download:", data)
        setDownloadLoading(false)
        sendDataLayer({ datalayer: { event: 'redownload', product_name: award.name, product_price: award.price }, repeat: true })
        if (data && (data.status === 402 || data.status === 400)) {
          setShowErrorDownload(true)
        }
      }).catch(error => {
        console.log("ERROR DOWNLOAD REDENTION:", error)
        setDownloadLoading(false)
        setShowErrorDownload(true)
      })
    } else {

      if (twoStepAuth) {
        console.log("Open Two Step Auth Factor.")
      } else {
        if (canRedem) {
          handlerRootModal()
          setAwardSelected({
            image: award.image,
            name: award.name,
            price: award.price,
            canRedem: canRedem,
            id: award.id,
            location: award.location
          })
        }
      }

    }
  }


  return (
    <div className='mc-awards-container__items--item__container'>

      <CustomModal
        title="Error en la descarga"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />


      {/* {award.prepurchased && !download && <Tag text="Precompra" />} */}
      {/* {showStock && <Tag position={"left"} text={`${award.stock} Disponible${award.stock > 1 ? 's' : ''}`} />} */}
      {type === 1 &&
        <>
          <div className="mc-awards-container__items--item__image">
            <img src={`/assets/images/${award.image}`} alt={award.name} />
          </div>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__name">{award.name}</div>
            <div className="mc-awards-container__items--item__price">COP {getPrice(award.price)}</div>
            {termLink &&
              <p>
                <Link to={termLink} target="_blank">
                  {termText}
                </Link>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={downloadLoading}
              status={canRedem ? 'active' : 'inactive'}
              onClick={(e) => handlerModal(e, award)}
            />
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div
              className="mc-awards-container__items--item__image"
              style={{ "backgroundImage": `url(/assets/images/${award.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__info">
              <div className="mc-awards-container__items--item__name">{award.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award.price}</div>
            </div>
          </div>
          {termLink &&
            <p>
              <a href={termLink} target='_blank' rel="noreferrer">
                {termText}
              </a>
            </p>
          }
          <Button
            type="primary"
            text={redemtionText}
            loading={false}
            status="active"
            onClick={(e) => handlerModal(e,)}
          />
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div
              className="mc-awards-container__items--item__image"
              style={{ "backgroundImage": `url(/assets/images/${award.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__labels">
              <div className="mc-awards-container__items--item__name">{award.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award.price}</div>
            </div>
          </div>
          <div className='mc-awards-container__items--item__actions'>
            {termLink &&
              <p>
                <a href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e,)}
            />
          </div>
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__image">
              <span style={{ "backgroundImage": `url(/assets/images/${award.image})` }}></span>
            </div>
            <div className="mc-awards-container__items--item__name">{award.name}</div>
            <div className="mc-awards-container__items--item__price">{getPrice(award.price)}</div>
            {/* <Tyc
          text={"Terminos"}
          // onClick={() => setShowTermsModal(true)}
           onClick={(e) => handlerModal(e,)}
        /> */}
            {/* <div>
              {termLink &&
                <p>
                  <a href="#" onClick={(e) => { e.preventDefault(); { setShowTermsModal(award) } }}>
                    {termText}
                  </a>
                </p>
              }
            </div> */}

            <Button
              type={'primary'}
              text={redemtionText}
              loading={false}
              status={canRedem ? 'active' : 'inactive'}
              img={imgSrc}
              onClick={(e) => handlerModal(e,)}
            />
          </div>
        </>
      }
    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState)(AwardBox)