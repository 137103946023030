import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'


// pages
import Login from "./components/pages/Login"
import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Redemptions from "./components/pages/Redemptions"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Alliances from "./components/pages/Alliances"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
import MajorAward from "./components/pages/MajorAward"
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice, getWhateverPage } from './utils/functions'
import { doRedeem } from './utils/helpers'
import useWidth from './hooks/useWidth'
import { sendDataLayer } from './utils/functions'

import './styles/main.scss'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const { width } = useWidth()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const [quantumName, setQuantumName] = useState('')
  const [quantumDoc, setQuantumDoc] = useState('')


  const home_page = '/mecanica'
  const pages = width < 992
    ? [
      { page: 'mecanica', title: '¿Cómo ganar?', sublevel: false, component: <Mechanic privatePage /> },
      { page: 'progreso', title: 'Progreso', sublevel: false, component: <Progress privatePage /> },
      { page: 'premios', title: 'Bonos', sublevel: false, component: <Awards privatePage /> },
      { page: 'premio-mayor', title: 'Premio mayor', sublevel: false, component: <MajorAward privatePage /> },
      { page: 'beneficios', title: 'Beneficios', sublevel: false, component: <Alliances privatePage /> },
      { page: 'mis-redenciones', title: 'Mis redenciones', sublevel: false, component: <Redemptions privatePage /> },
      { page: 'terminos-y-condiciones', title: 'Términos y condiciones', sublevel: false, component: <Terms /> },
      { page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', sublevel: false, component: <Faqs /> }
    ]
    : [
      { page: 'mecanica', title: '¿Cómo ganar?', sublevel: false, component: <Mechanic privatePage /> },
      { page: 'progreso', title: 'Progreso', sublevel: false, component: <Progress privatePage /> },
      { page: 'premios', title: 'Bonos', sublevel: false, component: <Awards privatePage /> },
      { page: 'premio-mayor', title: 'Premio mayor', sublevel: false, component: <MajorAward privatePage /> },
      { page: 'beneficios', title: 'Beneficios', sublevel: false, component: <Alliances privatePage /> },
      {
        page: 'mas', title: 'Otros', icon: `more`, sublevel: true, children: [
          { page: 'mis-redenciones', title: 'Mis redenciones', icon: 'redemtions', component: <Redemptions privatePage /> },
          { page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc', component: <Terms /> },
          { page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faqs', component: <Faqs /> }
        ]
      }
    ]

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout(() => {
        localStorage.removeItem('userNotFound')
      }, 3000)
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', ''))
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          console.log(loggedIn, public_url);
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', ''))
          if (public_child_url?.component?.props?.privatePage) navigate('/')
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      gtUser(token).then((user) => {
        console.log(user);

        if (user?.data) {
          setContext(user.data)

          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            window.location.href = home_page
          }
        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() => navigate('/'))

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    console.log("cancel bond")
    if (showConfirm) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index }, repeat: true })
    }
    setShowConfirm(!showConfirm)
  }

  const handlerRedemtion = () => {
    setRedemtionLoading(true)

    if (awardSelected.canRedem) {
      doRedeem(token, awardSelected.price, awardSelected.id, quantumName, quantumDoc).then((data) => {
        setRedemtionLoading(false)
        setShowConfirm(false)

        if (data && data.status === 200 && data.file) {
          setShowSuccessRedemtion(true)
          sendDataLayer({ datalayer: { event: 'redeem_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.id }, repeat: true })
          setTimeout(() => {
            window.location.href = "/mis-redenciones"
          }, 5000)
        } else {
          sendDataLayer({datalayer: {event: 'exhausted_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.id},repeat: true})
          setShowErrorRedemtion(true)
        }
      }).catch(error => {
        console.log("ERROR ON REDENTION:", error)
      })
    }
  }

  const buttonText = (
    <span>
      Descargar <img src={'assets/images/download.png'} alt="download" style={{ marginLeft: '5px', verticalAlign: 'middle' }} />
    </span>
  );

  return (
    <StateController.Provider
      value={
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        title="Datos incorrectos"
        desc="Parece que tus datos no están en nuestra base, por lo que esta vez no podrás participar. Pero no te preocupes, sigue disfrutando de todos los beneficios de tu Tarjeta."
        cta="Regresar al banco"
        isNotFound={true}
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Regresar al banco',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      {/* <CustomModal
        title="Términos y Condiciones"
        desc={'Aquí van los términos y condiciones...'}
        cta="Aceptar"
        show={true}
      // setShowModal={setShowTermsModal}
      /> */}

      <CustomModal
        show={showConfirm}
        text={'¿Quieres redimir este premio?'}
        setShowModal={setShowConfirm}
        image={awardSelected.image}
        desc={awardSelected.name}
        title={`${getPrice(awardSelected.price)}`}
        location={awardSelected.location}
        setQuantumName={setQuantumName}
        setQuantumDoc={setQuantumDoc}
        quantumName={quantumName}
        quantumDoc={quantumDoc}
        isRedemtionsModal={true}
        awardIndex={awardSelected.id}
        primaryAction={
          {
            action: handlerRedemtion,
            // buttonText: 'Descargar',
            buttonText: buttonText,
            loading: redemtionLoading
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar >"
          }
        }
      // terms={
      //   {
      //     termsText: "*Aplican términos y condiciones.",
      //     termsLink: "/terminos-y-condiciones"
      //   }
      // }
      />
      < CustomModal
        // title="Bonos agotados"
        text={"Bonos agotados"}
        desc={'Ya no te quedan bonos para redimir, pero sigue pagando con tu Tarjeta Mastercard para conseguir más.'}
        cta="Aceptar"
        image={awardSelected.image}
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />
      <CustomModal
        title="Redención correcta"
        desc={`Tu bono ha sido descargado. Podrás encontrarlo en la sección <strong>Redenciones</strong>.`}
        cta="Aceptar"
        image={awardSelected.image}
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
      />

      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'}`}>
        <Header
          headerType={width < 992 ? 4 : 1}
          logo="logoOccidenteMobile.png"
          items={pages}
          submenu={{
            type: "nickname",
            default: "A"
          }}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          {getWhateverPage(location, pages) &&
            <Route path={`${getWhateverPage(location, pages)}`} element={<NotFound />} />
          }

        </Routes>

        <Footer
          footerType={6}
          footerCardsImg="footer_cards.png"
          secondaryFooterImg="tarjetas.png"
          bankFooterImg="bank-footer.png"
          links={
            [
              {
                link: "/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          linksText="Válido del XX de xxxxxxxx de xxxx al XX de xxxxxx de xxxx."
          content="<p>*Campaña válida del 1 de abril de 2025 al 31 de mayo de 2025. Copyright 2025 y solo aplica para el territorio colombiano. Recuerde que por su seguridad el Banco de Occidente nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares. *Esta es una campaña oficial del Banco de Occidente. **Mastercard y el diseño de los círculos son marcas registradas de Mastercard International Incorporated.</p>"
          copyright="© Copyright 2025"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)