import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

// import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import Modal from '../molecules/Modal';
import SliderRotator from '../organisms/SliderRotator'
import { sendDataLayer } from '../../utils/functions';


function Login({ autologin, hash }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  const infoSectionRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEndCampaing, setShowEndCampaing] = useState(false)

  const scrollToInfoSection = () => {
    if (infoSectionRef.current) {
      infoSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowEndCampaing(false);
    sendDataLayer({ datalayer: { event: 'info_popup', text_title: '¡Gracias por participar!', link: 'N/A' }, repeat: true })

  };

  return (

    <div className='mc-page login slow_ani'>
      <Modal
        show={showEndCampaing}
        onClose={handleCloseModal}
        // title="¡Gracias por participar!"
        // text="Como viste tú y tu Tarjeta Mastercard <br/> hacen el mejor Match. Prepárate, porque <br/> viene mucho más."
        text="¿Viste que tu Tarjeta, sí paga? Aún tienes un<br/>bono por redimir, ¡aprovéchalo!"
        // subtext="Tienes desde el 4 de junio de 2024 hasta el 08 de agosto de 2024"
        imageUrl={`/assets/images/end_project.png`}
      />


      <Modal
        show={showModal}
        onClose={handleCloseModal}
        // title="¡Gracias por participar!"
        // text="Como viste tú y tu Tarjeta Mastercard <br/> hacen el mejor Match. Prepárate, porque <br/> viene mucho más."
        text="Como viste, tú y tu Tarjeta Mastercard <br/> siempre ganan juntos. Prepárate, vienen <br/> muchas sorpresas más."
        // subtext="Tienes desde el 4 de junio de 2024 hasta el 08 de agosto de 2024"
        imageUrl={`/assets/images/gracias_logo_modal.png`}
      />


      {/* <Container> */}
      <div className="loginContainer">
        <div className="textContainer">
          <div className='textContainerChild'>
            <div className='imgContainer'>
              <img src={`/assets/images/TitularMobileV3.png`} alt="" />
            </div>
            {/* {isDesktop ? (
              <div className='parrafoContainerDesktop'>
                <p className='parDescDesktop'>Haz compras con tu Tarjeta de Crédito Mastercard, <br />cumple tu meta
                  y redime increíbles premios.</p>
              </div>
            ) : (
              <div className='parrafoContainer'>
                <p>Haz compras con tu Tarjeta de Crédito <br /> Mastercard, cumple tu meta <br />
                  y redime increíbles premios.</p>
              </div>
            )} */}
          </div>
          <div>
            <LoginForm
              // title="Ingresa y conoce cómo ganar"
              type="credentials"
              fields={[
                { field: "document", label: "Ingresa los últimos 5 dígitos de tu documento", placeholder: "Número de documento", maxlength: 5, separated: false },
                { field: "birthdate", label: "Ingresa tu fecha de nacimiento", placeholder: "DD/MM/AAAA" },
                { field: "terms", label: "", termsText: "He leído y acepto los términos y condiciones", termsLink: "/terminos-y-condiciones" },
                { field: "personalData", label: "", termsText: "Acepto el uso y tratamiento de datos personales", termsLink: "/terminos-y-condiciones" }
              ]}
              buttonText="Ingresar"
              background="#fff"
              autologin={autologin}
              hash={hash}
            />
          </div>
        </div>
      </div>
      {isDesktop ? (
        <div onClick={scrollToInfoSection} className='arrow-down-container-desktop'>
          <a style={{ cursor: 'Pointer' }}>
            <img
              className={`arrow-down ${isHovered ? 'hover' : ''}`}
              src={`/assets/images/scroll-down.png`}
              alt=""
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </a>
        </div>
      ) : (
        <div onClick={scrollToInfoSection} className='arrow-down-container'>
          <a>
            <img
              className='arrow-down'
              src={`/assets/images/arrow_circle_down.png`}
              alt=""
            />
          </a>
        </div>
      )}
      <div className='cardsContainer'>
        <div className="informationText" ref={infoSectionRef}>
          {isDesktop ? (
            <>
              <h3 style={{ textAlign: 'left', paddingBottom: '10px' }} className='informationTextTitle' id='informationTextTitle'>
                Así puedes <br /> ganar
              </h3>
              <p style={{ textAlign: 'left', paddingTop: '0px', paddingBottom: '20px' }} className="informationTextPar">
                Conoce qué hacer para aprovechar tus compras, cumplir tu meta y ganar.
              </p>
            </>
          ) : (
            <>
              <h3 className='informationTextTitle' id='informationTextTitle'>
                Así puedes ganar
              </h3>
              <p className="informationTextPar">
                Conoce qué hacer para aprovechar tus compras, cumplir tu meta y ganar.
              </p>
            </>
          )}
        </div>
        <SliderRotator
          mobileItemsToShow={1}
          desktopItemsToShow={2.2}
          arrows={true}
          list={
            [
              {
                id: 1,
                image: "swiper1.png",
                title: "1. Empieza a participar",
                desc: "Escribe los últimos cinco dígitos de tu documento de identidad, incluye tu fecha de nacimiento, acepta los términos y condiciones, y haz clic en ingresar."
              },
              {
                id: 2,
                image: "swiper2.png",
                title: "2. Usa tu tarjeta para todo",
                desc: "Tendrás dos oportunidades para ganar. Te asignaremos una meta mensual que podrás cumplir mientras usas tu Tarjeta de Crédito Credencial - Mastercard."
              },
              {
                id: 3,
                image: "swiper3.png",
                title: "3. Gana y sigue ganando",
                desc: "Alcanza tu meta mensual y podrás ganar hasta 2 bonos en la vigencia de la campaña."
              },
              {
                id: 4,
                image: "swiper4.png",
                title: "4. La competencia sigue",
                desc: "Cumple tu meta tantas veces como sea posible y participa por un bono de $80.000.000 para la compra de un carro nuevo."
              }
            ]
          }
        />
      </div>

      {/* </Container> */}

    </div>
  )
}
export default Login