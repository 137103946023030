import React from 'react'

function CardBox({
  image,
  title,
  desc
}) {

  return (
    <div className='mc-card-container'>
      <div className='mc-card-img'>
        {image &&
          <img className="img-card" src={`/assets/images/${image}`} alt="Step image" />
        }
      </div>
      <div className='mc-card-text'>
        <div className="mc-card-text-title">
          {title &&
            <h2>{title}</h2>
          }
        </div>
        <div className="mc-card-text-par">
          {desc &&
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          }
        </div>
      </div>
    </div>
    // <div className={`mc-cardbox-container`}>
    //   {image &&
    //     <img src={`/assets/images/${image}`} alt="Step image" />
    //   }
    //   {title &&
    //     <h2>{title}</h2>
    //   }
    //   {desc &&
    //     <div dangerouslySetInnerHTML={{ __html: desc }} />
    //   }
    // </div>
  )
}

export default CardBox