import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'
import { useMediaQuery } from 'react-responsive';
import Container from '../../layouts/Container/Container'
// import PrimaryBanner from '../molecules/PrimaryBanner'
import AlliancesGallery from '../organisms/AlliancesGallery'
import Preloader from '../atoms/Preloader'



function Alliances() {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  const [alliances, setAlliances] = useState(false)
  const { context } = useContext(StateController)

  useEffect(() => {
    if (context && context.alliances) {

      const formater = context.alliances.filter((item) => {
        if (item.activate) {
          if (item.activate.includes('/')) {
            const timer = new Date(item.activate)
            const today = new Date()
            if (today.toDateString() == timer.toDateString()) return true

          } else if (item.activate !== '') {
            const today = new Date()
            const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
            const todayDay = days[today.getDay()]
            const enableDays = item.activate.split(',')
            let returner = false

            for (let i = 0; i < enableDays.length; i++) {
              if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
            }
            return returner
          }
        } else {
          return true
        }
      }).map((item) => {

        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule} dto`,
          image: item.image,
          termsData: item.terms,
          termLink: item.link
        }
      })

      setAlliances(formater)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  console.log(alliances, "mis aliansas mi fafa");

  return (
    <div className='mc-page alliances slow_ani '>

      {/* {isDesktop ? (<PrimaryBanner
        image="beneficios-bg-desk.png"
      />) : ("")} */}



      {/*  */}
      <div className='containerAlliances'>
        <Container>

          {isDesktop ? (
            <>
              <h1 className='informationTextTitle'>
                Aprovecha<br />descuentos<br />exclusivos
              </h1>
              <p className="informationTextPar">
                Cumple tu meta más rápido con estas alianzas.
              </p>
            </>
          ) : (
            <>
              <h1 className='informationTextTitle'>
                Aprovecha<br />descuentos<br />exclusivos
              </h1>
              <p className="informationTextPar">
                Cumple tu meta más rápido con estas alianzas.
              </p>
            </>
          )}
        </Container>
      </div>
      <Container>
        {alliances
          ? <AlliancesGallery
            alliedBoxType={4}
            itemsPerLine={3}
            limit={false}
            termsOnModal={true}
            limitButtonText="Ver todos los aliados"
            list={alliances}
            termText="Ver términos y condiciones"
            // termLink="/assets/pdf/legales-evd-2024-new-1.pdf"
          />
          : <Preloader
            type="section"
            invert
          />
        }
      </Container>

    </div >
  )
}
export default Alliances