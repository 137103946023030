import React, { useContext, useEffect, useState } from 'react'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import GoalCounter from '../atoms/GoalCounter'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Button from '../atoms/Button'
import { useMediaQuery } from 'react-responsive';
import { StateController } from './../../App'
import { sendDataLayer } from '../../utils/functions'

function Redemptions() {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  const { context } = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)
  

  useEffect(() => {
    if (context && context.redemtions) {
      console.log("Context redemtions:", context)
      setRedemtions(context.redemtions)
    }
  }, [context])


  if (!context || !context.user) {
    return <Preloader type="section" invert />;
  }

  console.log(context.user.user_type);

  const isEstambul = context.user.user_type === 1;
  const bannerImage = isEstambul ? 'estambul' : 'cancun';

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  // }, [])

  const layerProgress = () => {
    sendDataLayer({datalayer: { event: 'redemption_interaction', text: 'Ver términos y condiciones', link: '/terminos-y-condiciones' },repeat: true})
  }

  return (
    <div className='mc-page redemptions slow_ani'>
      <div className="containerRedemption">
        <Container>
          <div className="container-title-redemption">
            <h1 className='informationTextTitle'>Mis redenciones</h1>
            {isDesktop ? (
              <p className='informationTextPar'>
                Encuentra acá los bonos que has seleccionado. Sigue cumpliendo tu meta tantas veces como puedas y participa por un bono de <strong>$80.000.000</strong> para estrenar carro al final de la campaña.
              </p>
            ) : (
              <p className='informationTextPar'>
                <strong>Encuentra acá los bonos que elegiste, sigue cumpliendo tu meta la mayor cantidad de veces para participar por un premio especial al finalizar la campaña. </strong>
              </p>
            )}
          </div>
          {redemtions
            ? <AwardsGallery
              awardBoxType={4}
              itemsPerLine={3}
              list={redemtions}
              showStock={false}
              canRedem={true}
              download={true}
              redemtionText="Descargar"
              // termText="Términos y condiciones"
              // termLink="/terminos-y-condiciones"
            />
            : <Preloader
              type="section"
              invert
            />
          }
        </Container>
      </div>

      {isDesktop ? (
        <Container>
          <div className="container-travel">
            <div className='mc-progress__big_award title_redentions_container'>
              <h2 className='informationTextTitle'>Sigue participando</h2>
              <h3 className='informationTextSubTitle'>un carro nuevo espera por ti</h3>
              <p className='informationTextPar minimal-width'>
                Si eres de los que más veces cumple la meta que te ha sido asignada, podrás participar por un carro nuevo.
              </p>

              <GoalCounter
                label={`Metas cumplidas:`}
                simpleVersion={true}
                // rule={'complete'} // decimal - complete
              />
              <div onClick={layerProgress}>
                <Button
                  type="primary"
                  text={'Ver términos y condiciones'}
                  loading={false}
                  status={'active'}
                  externalButton={true}
                  link="/terminos-y-condiciones"
                />
              </div>
            </div>
            <div className='redemption-trav'>
              <PrimaryBanner
                image={`${bannerImage}.png`}
              />
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className='mc-progress__big_award title_redentions_container'>
            <h2 className='informationTextTitle'>Sigue participando</h2>
            <h3 className='informationTextSubTitle'>un carro nuevo espera por ti</h3>
            <p className='informationTextPar'>
            Si eres de los que más veces cumple la meta que te ha sido asignada, podrás participar por un carro nuevo.
            </p>

            <GoalCounter
              label={`Metas cumplidas`}
              rule={'complete'} // decimal - complete
            />
          </div>

          <div onClick={layerProgress}>
            <Button
              type="primary"
              text={'Ver términos y condiciones'}
              loading={false}
              status={'active'}
              link="/terminos-y-condiciones"
            />
          </div>


          <PrimaryBanner
            image={`${bannerImage}.png`}
          />

        </Container>
      )}
    </div>
  )
}
export default Redemptions