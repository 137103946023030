import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  img,
  imgl,
  externalButton
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '';

  const handler = () => {
    if (status === 'active' && !loading) {

      if (externalButton) {
        window.location.href = link
      } else {
        link ? navigate(link) : onClick();
      }
      
      // link ? navigate(link) : onClick && onClick();
    }
  };

  const showImgL = !isHomePage && imgl;
  const showImg = !isHomePage && img;
  const showLoader = loading;

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status}`}>
        {showImgL && <img src={`assets/images/${imgl}`} alt="" />}
        {showLoader ? (
          <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
        ) : (
          text
        )}
        {showImg && <img src={`assets/images/${img}`} alt="" />}
      </button>
    </div>
  );
}

export default Button;
