import React from 'react'

const BackButton = ({ image, alt, text }) => {

    const handleClick = () => {
        window.history.back();
    }

    return (
        <div className="back-button" onClick={handleClick}>
            <div>
                <img src={`/assets/images/${image}`} alt={alt} />
            </div>
            <div>
                <span>{text}</span>
            </div>
        </div>
    )
}

export default BackButton