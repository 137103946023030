import React, { useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import FormQuantumData from './FormQuantumData'
import { sendDataLayer } from '../../utils/functions'

function CustomModal({
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  cta,
  text,
  location,
  setQuantumName,
  setQuantumDoc,
  quantumName,
  quantumDoc,
  showPrimaryButton = true,
  additionalTitle,
  isNotFound,
  isRedemtionsModal,
  awardIndex
}) {
  const { setMdalOpen } = useContext(StateController)

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])

  const close = () => {
    setShowModal(false)

    if (secondaryAction & secondaryAction.action) {
      secondaryAction.action();
    }

    if (isRedemtionsModal) {
      sendDataLayer({ datalayer: { event: 'cancel_bond', product_name: desc, product_price: title, index: awardIndex }, repeat: true })
    }
  }

  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani ${isNotFound ? 'isNotFound' : ''}`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className="mc-custom-modal__center">
        <VerticalCenter>
          <div className="mc-custom-modal__center--window slow_ani">
            {isNotFound &&
              <img src="/assets/images/notfound.png" className='not-found-image' />
            }
            <h2 className='informationTextTitleModal'>
              {isNotFound
                ? <>¡Ups! <br />No encontramos<br /> tu registro</>
                : text
              }
            </h2>
            <div className="mc-custom-modal__close" onClick={close}>
              <Icon name="close" />
            </div>
            {image &&
              <div
                className="mc-custom-modal__center--window__image"
                style={{ "backgroundImage": `url(/assets/images/${image})` }}
              >
              </div>
            }
            {additionalTitle && (
              <div
                className="informationTextTitleModal"
                dangerouslySetInnerHTML={{ __html: additionalTitle }}
              />
            )}
            <div className="mc-custom-modal__center--window__desc">
              <div dangerouslySetInnerHTML={{ __html: desc }} />
            </div>
            {!isNotFound &&
              <div className="mc-custom-modal__center--window__title">{title}</div>
            }
            <div>
              {/* <p className="informationTextParrModal">Sed ut perspiciatis unde omnis iste natus error sit <br /> voluptatem accusantium doloremque</p> */}
            </div>
            {terms &&
              <div className="mc-custom-modal__center--window__terms">
                <Link to={terms.termsLink} target="_blank">
                  {terms.termsText}
                </Link>
              </div>
            }
            {
              location > 0
                ? (
                  <FormQuantumData
                    setQuantumName={setQuantumName}
                    setQuantumDoc={setQuantumDoc}
                    quantumName={quantumName}
                    quantumDoc={quantumDoc}
                    location={location}
                  />
                )
                : <></>
            }
            <div className="mc-custom-modal__center--window__actions">
              {showPrimaryButton && (
                <Button
                  type="primary"
                  text={primaryAction ? primaryAction.buttonText : cta}
                  loading={primaryAction ? primaryAction.loading : false}
                  status={
                    (location > 0 && (!quantumName || !quantumDoc))
                      ? 'inactive'
                      : 'active'
                  }
                  onClick={
                    (location > 0 && (!quantumName || !quantumDoc))
                      ? null
                      : (primaryAction ? primaryAction.action : null)
                  }
                  disabled={location > 0 && (!quantumName || !quantumDoc)}
                />
              )}
              {secondaryAction &&
                <Button
                  type="secondary"
                  text={secondaryAction.buttonText}
                  loading={false}
                  status="active"
                  onClick={secondaryAction.action}
                />
              }
            </div>

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal