import React from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CustomNextArrow, CustomPrevArrow } from '../atoms/ArrowsSlice';


function SliderRotator({
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  arrows
}) {

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: false,
    arrows: arrows ? arrows : false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1.5,
          slidesToScroll: 1,
          autoplay: false,
          arrows: false,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
          // centerPadding: '20px',
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        }
      }
    ]
  }

  return (
    <div className={`mc-slider-rotator`}>
      <Slider {...settings}>
        {list.map((slide, index) => {
          return (
            <div key={index} className='mc-slider-rotator__slide'>
              <CardBox
                image={slide.image}
                title={slide.title}
                desc={slide.desc}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SliderRotator