import React from 'react'

function Icon(props) {

  return (
    <div className={`mc-icon`}>
      {props.name === "test" &&
        <span className={`icon-${props.name}`}>
          <img src={`/assets/images/${props.name}.png`} alt='Boton Test' />
        </span>
      }
      {props.name === "icon" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.103 2H14.897C15.2911 1.99995 15.6814 2.07756 16.0455 2.22838C16.4096 2.3792 16.7404 2.60029 17.019 2.879L21.12 6.979C21.3993 7.25791 21.6207 7.58917 21.7717 7.95382C21.9227 8.31846 22.0003 8.70932 22 9.104V14.898C22 15.2921 21.9224 15.6824 21.7716 16.0465C21.6208 16.4106 21.3997 16.7414 21.121 17.02L17.021 21.121C16.7423 21.4 16.4114 21.6214 16.0471 21.7724C15.6828 21.9233 15.2923 22.001 14.898 22.001H9.103C8.70883 22.0009 8.31854 21.9232 7.95443 21.7722C7.59033 21.6212 7.25955 21.3999 6.981 21.121L2.88 17.021C2.60087 16.7422 2.37946 16.4111 2.22846 16.0466C2.07746 15.6822 1.99982 15.2915 2 14.897V9.103C1.99995 8.7089 2.07756 8.31865 2.22838 7.95455C2.3792 7.59045 2.60029 7.25963 2.879 6.981L6.979 2.88C7.25791 2.60075 7.58917 2.37927 7.95382 2.22827C8.31846 2.07726 8.70932 1.99969 9.104 2H9.103Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16V16.01" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13C12.4498 13.0014 12.8868 12.8511 13.2407 12.5734C13.5945 12.2958 13.8444 11.907 13.95 11.4698C14.0557 11.0327 14.0109 10.5726 13.8229 10.1641C13.6349 9.75549 13.3147 9.42219 12.914 9.21801C12.5162 9.01422 12.0611 8.95103 11.6228 9.03873C11.1845 9.12642 10.7888 9.35984 10.5 9.70101" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "close" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M1.31728 16.6346L0 15.3173L7 8.31728L0 1.31728L1.31728 0L8.31728 7L15.3173 0L16.6346 1.31728L9.63456 8.31728L16.6346 15.3173L15.3173 16.6346L8.31728 9.63456L1.31728 16.6346Z" fill="#2B3550" />
          </svg>
        </span>
      }
      {props.name === "faq" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <mask id="mask0_496_211"  maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
              <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_496_211)">
              <path d="M12.5 15.675L6.5 9.67502L7.775 8.40002L12.5 13.125L17.225 8.40002L18.5 9.67502L12.5 15.675Z" fill="#333333" />
            </g>
          </svg>
        </span>
      }
      {props.name === "close_session" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.6665 5.33335V4.00002C6.6665 3.6464 6.80698 3.30726 7.05703 3.05721C7.30708 2.80716 7.64622 2.66669 7.99984 2.66669H12.6665C13.0201 2.66669 13.3593 2.80716 13.6093 3.05721C13.8594 3.30726 13.9998 3.6464 13.9998 4.00002V12C13.9998 12.3536 13.8594 12.6928 13.6093 12.9428C13.3593 13.1929 13.0201 13.3334 12.6665 13.3334H7.99984C7.64622 13.3334 7.30708 13.1929 7.05703 12.9428C6.80698 12.6928 6.6665 12.3536 6.6665 12V10.6667" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 8H2L4 6" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 10L2 8" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "menu" &&
        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 20V16.6667H21.6667V20H0ZM27.6667 18.3333L19.3333 10L27.6667 1.66667L30 4L24 10L30 16L27.6667 18.3333ZM0 11.6667V8.33333H16.6667V11.6667H0ZM0 3.33333V0H21.6667V3.33333H0Z" fill="white" />
        </svg>
      }


      {props.name === 'block' &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <mask id="mask0_498_997" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
              <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_498_997)">
              <path d="M5.99417 18C5.58139 18 5.22917 17.8531 4.9375 17.5594C4.64583 17.2656 4.5 16.9125 4.5 16.5V8.5C4.5 8.0875 4.64687 7.73438 4.94062 7.44063C5.23437 7.14688 5.5875 7 6 7H6.5V5C6.5 3.89333 6.89049 2.95 7.67146 2.17C8.45229 1.39 9.39674 1 10.5048 1C11.6127 1 12.5556 1.39 13.3333 2.17C14.1111 2.95 14.5 3.89333 14.5 5V7H15C15.4125 7 15.7656 7.14688 16.0594 7.44063C16.3531 7.73438 16.5 8.0875 16.5 8.5V16.5C16.5 16.9125 16.3531 17.2656 16.0592 17.5594C15.7653 17.8531 15.4119 18 14.9992 18H5.99417ZM6 16.5H15V8.5H6V16.5ZM10.5044 14C10.9181 14 11.2708 13.8527 11.5625 13.5581C11.8542 13.2635 12 12.9094 12 12.4956C12 12.0819 11.8527 11.7292 11.5581 11.4375C11.2635 11.1458 10.9094 11 10.4956 11C10.0819 11 9.72917 11.1473 9.4375 11.4419C9.14583 11.7365 9 12.0906 9 12.5044C9 12.9181 9.14729 13.2708 9.44187 13.5625C9.73646 13.8542 10.0906 14 10.5044 14ZM8 7H13V5C13 4.30556 12.7569 3.71528 12.2708 3.22917C11.7847 2.74306 11.1944 2.5 10.5 2.5C9.80556 2.5 9.21528 2.74306 8.72917 3.22917C8.24306 3.71528 8 4.30556 8 5V7Z" fill="#333333" />
            </g>
          </svg>
        </span>
      }


    </div>
  )
}

export default Icon