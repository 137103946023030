import React from 'react';
import Button from '../atoms/Button';

const Modal = ({ show, onClose, text, subtext, imageUrl }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="modal-overlay">
            <div className="modal">
                <button className="close-button" onClick={onClose}>×</button>
                <img src={imageUrl} alt="Profile" className="modal-image" />
                <h2>¡La campaña ha <br />terminado!</h2>
                <p dangerouslySetInnerHTML={{ __html: text }}></p>
                <small>{subtext}</small>
                <Button
                    type="primary"
                    text="Ver bonos disponibles"
                    status="active"
                    onClick={onClose}
                >
                </Button>
            </div>
        </div>
    )
}

export default Modal