import React, { useState, useEffect } from 'react'
import { sendDataLayer } from '../../utils/functions'

import Icon from './Icon'

function Accordeon({
  allItems, 
  setAllItems, 
  title, 
  content
}) {
  const [show, setShow] = useState(false)

  const toggler = (e) => {
    setAllItems(!allItems)

    if (e.target.closest('.mc-accordeon-container.active')) {
      return false
    }

    setTimeout( () => {
      setShow(true)

      sendDataLayer({datalayer: {event: 'frequently_questions', question: title},repeat: true})
    }, 50 )
  }

  useEffect(() => {
    setShow(false)
  }, [allItems])

  return (
    <div className={`mc-accordeon-container slow_ani ${show ? 'active' : ''}`}>
      <div
        className="mc-accordeon-toggle slow_ani"
        onClick={toggler}
      >
        {title}
        <div className="mc-accordeon-toggle-icon">
          <Icon name="faq" />
        </div>
      </div>
      {show &&
        <div className="mc-accordeon-content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      }
    </div>
  )
}

export default Accordeon