import React from 'react';
import prevArrowImage from '../../../src/chevron_left.png'; 
import nextArrowImage from '../../../src/chevron_right.png'; 

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    >
      <img src={prevArrowImage} alt="Prev" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', zIndex: 1 }}
      onClick={onClick}
    >
      <img src={nextArrowImage} alt="Next" />
    </div>
  );
};

export { CustomPrevArrow, CustomNextArrow };