import React from 'react'
import Container from '../../layouts/Container/Container'
import BackButton from '../atoms/BackButton'

function Terms() {

  return (
    <div className='mc-page terms slow_ani terms-page'>

      <div className='containerTerm'>
        <Container>
          <div className="page-header">
            <h1 className="informationTextTitle" >Términos y condiciones</h1>
          </div>
        </Container>
      </div>
      <BackButton
        image={'back.png'}
        alt={'boton regresar'}
        text={'Button'}
      />
      <Container>
        <div className='mc-static-content'>
<div className="mc-terms-container">
  <div>
    <h5 className="mc-terms-container__title">Términos y condiciones de la campaña “Hacer es ganar - con un sí se gana” - Banco de Occidente</h5>
    <p className="mc-terms-container__parr">La campaña <strong className='tyc-black'>&quot;Hacer es ganar - con un sí se gana&quot;</strong> (en adelante <strong className='tyc-black'>&quot;La Campaña&quot;</strong>) está dirigida a clientes Persona Natural, titulares de las Tarjetas de Crédito de las categorías <strong className='tyc-black'>Clásica, Gold, Platinum y Black Mastercard</strong> del Banco de Occidente (en adelante el/los <strong className='tyc-black'>&quot;Cliente(s)&quot;</strong>) y tiene por objeto incentivar a los Clientes a utilizar sus Tarjetas de Crédito de las categorías Clásica, Gold, Platinum, y Black Mastercard (en adelante la <strong className='tyc-black'>&quot;Tarjeta de crédito&quot;</strong>), otorgando a los Clientes que cumplan con los términos y condiciones de La Campaña, la posibilidad de ganar: Bonos digitales dentro del rango de $30.000 COP a $400.000 COP, los cuales podrán redimir en distintos establecimientos de comercio tales como: Netflix, Rappi, Uber, Ktronix, Beer Station, Crepes & Waffles, El Corral, Frisby, Sandwich Qbano, Alkosto, Carulla, Jumbo, Inkanta, Laika, Pepe Ganga, Baby Fresh, Pat Primo, Seven Seven, Tennis, Totto o Vélez, y un Premio mayor, que consiste en un (1) bono por un valor de OCHENTA MILLONES DE PESOS M/CTE ($80.000.000 COP) para la compra de un vehículo nuevo; de acuerdo con lo mencionado en el numeral 4.2 de los presentes términos y condiciones.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>1. VIGENCIA Y COBERTURA: </strong></p>
    <p className="mc-terms-container__parr">La Campaña estará vigente desde el día <strong className='tyc-black'>01 de abril de 2025 a las 12:00:01 a.m. hasta el día 31 de mayo de 2025 a las 11:59:59 p.m.</strong></p>
    <p className="mc-terms-container__parr">La Campaña aplica únicamente para Tarjetahabientes residentes y radicados en Colombia.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>2. ¿QUIÉNES PUEDEN PARTICIPAR?:</strong></p>
    <p className="mc-terms-container__parr">Los Clientes que deseen participar deberán cumplir con los siguientes requisitos: </p>
    <p className="mc-terms-container__parr">1. Tener más de 18 años.</p>
    <p className="mc-terms-container__parr">2. Estar domiciliado en Colombia.</p>
    <p className="mc-terms-container__parr">3. Ser titular de la Tarjeta de crédito, desde el inicio de la Vigencia de la Campaña, hasta la entrega del Premio.</p>
    <p className="mc-terms-container__parr">4. Haber recibido la invitación para participar en la Campaña al correo electrónico y/o SMS registrado en la entidad. Si el Cliente no recibe esta comunicación no podrá participar.</p>
    <p className="mc-terms-container__parr">5. No estar en mora en ninguna de sus obligaciones con el Banco.</p>
    <p className="mc-terms-container__parr">6. Tarjetahabientes titulares de Tarjeta(s) de Crédito Credencial amparadas, participarán de manera independiente, siempre que cumplan con las condiciones de la Campaña.</p>
    <p className="mc-terms-container__parr">7. <strong className='tyc-black'> No participan:</strong> Tarjetas de Crédito Empresariales, Tarjetas de Crédito Franquicia Visa y Tarjetas Débito MasterCard.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>Todo Cliente que participe en La Campaña conoce y acepta:</strong></p>
    <p className="mc-terms-container__parr">1. Las condiciones, obligaciones y limitaciones establecidas en los Términos y Condiciones.</p>
    <p className="mc-terms-container__parr">2. Las decisiones que tome El Banco en relación con La Campaña como disposiciones de carácter definitivo. Cualquier violación a lo estipulado en el presente documento, a los procedimientos o sistemas establecidos para la realización de La Campaña implicará la inmediata exclusión de esta y/o la revocación de los Premios.</p>
    <p className="mc-terms-container__parr">Los Clientes podrán, de forma escrita y en cualquier momento durante la vigencia de La Campaña <strong className='tyc-black'>renunciar a participar en esta por medio de las oficinas del Banco o a través de la Línea de Atención al cliente 601 390 2058 en Bogotá o al 01 8000 51 4652 a nivel nacional.</strong></p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>3. MECÁNICA: </strong></p>
    <p className="mc-terms-container__parr">Para participar por uno de los bonos digitales o el premio mayor, los Clientes deberán: </p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>3.1. Acceder a la página web www.haceresganarbancodeoccidente.com y consultar su meta personal de facturación</strong> utilizando los últimos 5 dígitos de la cédula como usuario y la fecha de nacimiento como contraseña.</p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>3.2. Cumplir la meta personal asignada para cada mes, que es individual y personalizada para cada Cliente.</strong> Para ello el Cliente deberá tener en cuenta los siguientes parámetros:</p>
    <ul>• Cada tarjetahabiente, tendrá asignada una meta mensual (la &quot;Meta&quot;) compuesta por facturación (en monto) que podrá cumplir durante cada mes de campaña y dentro del periodo de vigencia de esta.</ul>
    <ul>• Esto implica que, para el segundo mes, el progreso de la meta se reinicia y todos tienen una segunda oportunidad de volver a cumplir la meta. Las metas serán comunicadas nuevamente y podrían ser diferentes a las iniciales.</ul>
    <ul>• Por el cumplimiento de la meta del mes, el cliente podrá acceder a un (01) bono digital que podrá redimir en los comercios que se encuentren disponibles en la plataforma de campaña. Sin embargo, el cliente podrá seguir facturando y usando su tarjeta de crédito para participar por el premio mayor.</ul>
    <ul>• El cliente podrá ganar y redimir hasta dos bonos digitales durante la vigencia de la campaña, siempre que cumpla su meta y las condiciones de la campaña durante cada oportunidad para ganar: primera oportunidad del 1 al 30 de abril 2025 y segunda oportunidad del 1 al 31 de mayo 2025.</ul>
    <ul>• El Cliente recibirá correos electrónicos en la dirección registrada en el Banco, a través de los cuales podrá visualizar tanto su Meta como su progreso.</ul>
    <ul>• El Participante podrá consultar su progreso en el cumplimiento de la Meta al ingresar a: <strong className='tyc-black'>www.haceresganarbancodeoccidente.com</strong></ul>
    <ul>• La información transaccional y avances en el cumplimiento de la Meta de cada participante serán actualizados en la plataforma preferiblemente de manera semanal, sin embargo, este tiempo puede variar.</ul>
    <ul><strong className='tyc-black'>Para efectos de contabilización de la Meta, se tendrán en cuenta los siguientes aspectos: </strong></ul>
    <ul>• Si el Participante tiene más de una Tarjeta de crédito, las transacciones que realice con todas sus <strong className='tyc-black'>Tarjetas de crédito (Mastercard)</strong> se sumarán en el cumplimiento de la Meta.</ul>
    <ul>• Participarán las compras nacionales e internacionales. Las compras internacionales se contabilizarán con la TRM de la fecha de la transacción.</ul>
    <ul style={{ textDecoration: 'underline' }}>• Las transacciones que NO serán contabilizadas dentro de La Campaña serán las siguientes: </ul>
    <ul>o Transferencias, retiros en cajeros electrónicos y/o consignaciones.</ul>
    <ul>o Pagos de impuestos.</ul>
    <ul>o Transacciones que se realicen a través de PSE.</ul>
    <ul>o Transacciones realizadas con <strong className='tyc-black'>Tarjetas de crédito</strong> bloqueadas, inactivas o canceladas por cualquier causa.</ul>
    <ul>o Avances o compras de cartera.</ul>
    <ul>Si al inicio de La Campaña el Cliente tenía una Tarjeta de crédito habilitada para participar y en el transcurso de esta solicita cambio del plástico o éste expira, las transacciones que haya realizado con esta Tarjeta de crédito se contabilizarán en el cumplimiento de la Meta y podrá ingresar a la plataforma con el mismo usuario y contraseña predeterminados enviados en las comunicaciones de La Campaña. Si transcurridos quince (15) días hábiles a partir de la fecha de activación de la nueva Tarjeta de Crédito, se presentan problemas de registro, el Cliente deberá comunicarse con las <strong className='tyc-black'>Líneas de Atención al cliente 601 390 2058 en Bogotá o 01 8000 51 4652 a nivel nacional.</strong></ul>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>3.3. Los Clientes que adicionalmente deseen participar por el Premio mayor:</strong> Se elegirá al Cliente que mayor número de veces haya cumplido con la Meta personal asignada. En caso de empate se elegirá al Cliente con mayor facturación realizada durante la Vigencia de La Campaña.</p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>Importante:</strong></p>
    <ul>• A lo largo de la campaña el Banco se reserva el derecho de activar nuevos retos y/o premios durante el periodo de vigencia, lo cual será informado a través de la Plataforma o a través de cualquier otro medio que el Banco estime que sea eficaz para tal fin.</ul>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>4. PREMIO: </strong>los Clientes podrán participar por dos tipos de premios:</p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>4.1. Bonos digitales:</strong> los Clientes que cumplan con los requisitos y la dinámica descrita en los puntos 2 y 3, podrán ganar un Bono digital en cualquiera de los siguientes establecimientos de comercio: Netflix, Rappi, Uber, Ktronix, Beer Station, Crepes & Waffles, El Corral, Frisby, Sandwich Qbano, Alkosto, Carulla, Jumbo, Inkanta, Laika, Pepe Ganga, Baby Fresh, Pat Primo, Seven Seven, Tennis, Totto o Vélez.</p>
    <p className="mc-terms-container__parr">El valor del Bono digital será asignado por el Banco de acuerdo con la Categoría de Tarjeta de crédito y meta personal asignada al Cliente y podrá variar dentro de los siguientes rangos: $30.000, $60.000, $80.000, $100.000, $200.000, $300.000 o $400.000 pesos colombianos.</p>
    <p className="mc-terms-container__parr">El cliente deberá redimir el bono en la página web de la campaña descrita con anterioridad y deberá aceptar las condiciones de redención y vigencia según el comercio.</p>
    <p className="mc-terms-container__parr">Una vez el cliente cumpla la meta, se habilitará la redención en plataforma y habrá un tiempo límite para redimir el premio en cada oportunidad para ganar: las redenciones de los premios de la primera oportunidad estarán habilitados hasta el 15 de mayo de 2025 y en la segunda oportunidad hasta el 15 de junio de 2025 o hasta que se agote la bolsa disponible.</p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>Importante:</strong></p>
    <ul>• El Banco entregará un <strong className='tyc-black'>monto máximo de $110.000.000 de pesos colombianos</strong> en Bonos digitales, los cuales se otorgarán de acuerdo con la mecánica de la Campaña.</ul>
    <ul>• Los Bonos digitales estarán <strong className='tyc-black'>disponibles hasta que se agote la bolsa de $110.000.000 de pesos.</strong></ul>
    <ul>• Los Bonos digitales están <strong className='tyc-black'>sujetos a disponibilidad</strong> de cada comercio.</ul>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>4.2. Premio mayor: </strong> los Clientes, que cumplan con los requisitos y dinámica descritos en los puntos anteriores, podrán participar por un premio mayor que consiste en un (1) bono por un valor de OCHENTA MILLONES DE PESOS M/CTE ($80.000.000) COP para la compra de un vehículo nuevo.</p>
    <ul>• Se elegirá al Cliente que mayor número de veces hayan cumplido con la Meta personal asignada.</ul>
    <ul>• En caso de empate se elegirá al Cliente con mayor facturación realizada durante la Vigencia de La Campaña.</ul>
    <p className="mc-terms-container__parr">Para conocer los ganadores los Clientes podrán comunicarse con las Línea de Atención al cliente habilitadas por <strong className='tyc-black'>El Banco 601 390 2058 en Bogotá o 01 8000 51 4652 a nivel nacional.</strong></p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>Importante:</strong></p>
    <ul>• El bono correspondiente al premio mayor por un valor de OCHENTA MILLONES DE PESOS M/CTE ($80.000.000) COP es válido únicamente para la compra de un vehículo nuevo en el concesionario AUTONIZA. El uso del bono está sujeto a las condiciones indicadas por Banco de Occidente en el momento de hacerle entrega del premio al ganador.</ul>
    <ul>• La redención del bono para la compra de un vehículo nuevo podrá hacerse en vehículos nuevos de las marcas y modelos disponibles en AUTONIZA como: Chevrolet, Ford, Mercedes Benz, Kia, Suzuki, Citroën, Jac, Karry o Jet Tour. La disponibilidad de las referencias y colores, así como los tiempos de importación y entrega del vehículo seleccionado estarán a cargo de AUTONIZA y estarán sujetos a disponibilidad del inventario existente en Colombia al momento de adquirir el vehículo. AUTONIZA es el responsable por la calidad e idoneidad de los vehículos ofrecidos.</ul>
    <ul>• La redención del bono se deberá realizar por el ganador, en alguno de los concesionarios de AUTONIZA ubicado en las siguientes ciudades: Bogotá, Medellín, Cali, Barranquilla, Cartagena, Manizales, Pereira, Armenia, Santa Marta, Valledupar, Montería, Neiva, Ibagué, Villavicencio, Tunja o Bucaramanga. El ganador informará, en el momento en que sea notificado como ganador del premio, la ciudad donde desea redimir el bono de la lista de ciudades enunciadas. En caso de que el ganador se encuentre en una ciudad diferente a las ya mencionadas, deberá trasladarse a cualquier de estas ciudades enlistadas para redimir el bono. El ganador debe tener en cuenta lo siguiente: Banco de Occidente ni AUTONIZA no asumirán los gastos de traslado o manutención del ganador hacia la ciudad seleccionada y las instalaciones físicias del los concesionarios del proveedor para la redención del bono.</ul>
    <ul>• Con el valor total del bono del Premio, el ganador podrá cubrir el precio del vehículo seleccionado (de acuerdo con los inventarios y disponibilidad del concesionario AUTONIZA), así como los gastos de matrícula, SOAT e impuestos que se generen y demás trámites necesarios para la entrega del vehículo.</ul>
    <ul>• En el evento en que no se redima el valor total del bono y se genere un saldo a favor del ganador, el mismo podrá ser utilizado en servicio de postventa (revisiones, reparaciones, entre otros), siempre que sea utilizado dentro de la fecha de vigencia del bono y en el vehículo adquirido con el Premio. No se hará entrega de suma económica al ganador, en caso de que se genere saldo a su favor después de redimir el bono.</ul>
    <ul>• En caso de que el costo del auto y demás gastos superen el valor del bono, el excedente deberá ser asumido por el ganador.</ul>
    <ul>• Todo lo anterior, sujeto a la disponibilidad y horario de AUTONIZA. Banco de Occidente no será responsable por los trámites y servicios que no estén disponibles en el concesionario. Tampoco será responsable de los trámites y servicios realizados por el concesionario AUTONIZA.</ul>
    <ul>• Para realizar el trámite de matrícula se deben respetar y cumplir todas las normas y términos vigentes establecidos por las autoridades. En caso de que los ganadores tengan comparendos vigentes, será de su exclusiva responsabilidad el pago de estos para poder efectuar la matrícula del vehículo.</ul>
    <ul>• Para hacer efectivo el bono es necesario que el ganador se identifique con el documento de identidad y que sus datos correspondan a los reportados por Banco de Occidente. El vehículo sólo podrá ser matriculado al nombre del ganador.</ul>
    <ul>• El bono tendrá una vigencia de doce (12) meses para ser utilizado a partir de la fecha de entrega.</ul>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>5. ENTREGA Y CONNOTACIONES DE LOS PREMIOS</strong></p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>5.1. Bonos Virtuales:</strong> Los clientes que cumplan sus Metas asignadas, podrán ingresar a la página <strong className='tyc-black'>www.haceresganarbancodeoccidente.com</strong>, elegir el bono a redimir y descargar el bono en un documento PDF.</p>
    <p className="mc-terms-container__parr">En cuanto cumpla la meta asignada para cada mes se habilitará la redención del bono.</p>
    <p className="mc-terms-container__parr">El cliente podrá ganar y redimir hasta dos (2) bonos digitales durante el periodo de vigencia de la campaña. Uno por cada oportunidad para ganar o mes de campaña: en la primera oportunidad puede redimir hasta el 15 de mayo de 2025 y en la segunda oportunidad hasta el 15 de junio de 2025.</p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>5.2. Premio Mayor: </strong> pasados 45 días hábiles del cierre de la campaña el ganador será notificado primero mediante una llamada telefónica y posteriormente, la aceptación del premio se efectuará a través de una carta que el ganador deberá firmar y enviar por correo electrónico a: <strong className='tyc-black'>JMGomez@bancodeoccidente.com.co</strong>, junto con copia de la cédula de ciudadanía a más tardar un (1) día hábil después de enviado el correo con el documento.</p>
    <p className="mc-terms-container__parr">En caso de que el Participante ganador presente algún inconveniente con el Premio recibido deberá notificarlo dentro de los treinta (30) días hábiles siguientes a la fecha en que lo recibió, de lo contrario, El Banco entenderá que el Incentivo se recibió con satisfacción.</p>
    <p className="mc-terms-container__parr">Los ganadores podrán ser consultados en el siguiente link: <a href='https://www.bancodeoccidente.com.co/wps/portal/banco-de-occidente/bancodeoccidente/para-personas/tarjeta-de-credito/nuestras-tarjetas'>https://www.bancodeoccidente.com.co/wps/portal/banco-de-occidente/bancodeoccidente/para-personas/tarjeta-de-credito/nuestras-tarjetas</a></p>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>5.3.</strong> Para reportar los inconvenientes, el Participante deberá comunicarse con las Línea de Atención al cliente habilitadas por <strong className='tyc-black'>El Banco 601 390 2058 en Bogotá o 01 8000 51 4652 a nivel nacional.</strong></p>
    <p className="mc-terms-container__parr">Dado el caso que, para solucionar el inconveniente presentado por el Participante con el incentivo y buscar su satisfacción, se le llegará a ofrecer un bono digital de resarcimiento y éste aceptará de manera verbal, escrita u otro medio este nuevo premio, se entiende que el Participante está renunciando al Incentivo originalmente estipulado para La Campaña.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>6. RESPONSABILIDAD:</strong></p>
    <p className="mc-terms-container__parr">El Banco no se hace responsable en las siguientes circunstancias:</p>
    <p className="mc-terms-container__parr">1. El incumplimiento de sus obligaciones, cuando tal incumplimiento total o parcial se produzca por causas o circunstancias constitutivas de fuerza mayor o caso fortuito, calificadas de conformidad con la ley aplicable.</p>
    <p className="mc-terms-container__parr">2. Los gastos que no se encuentren especificados dentro de los presentes Términos y condiciones.</p>
    <p className="mc-terms-container__parr">3. Los reclamos presentados en cuanto a la calidad, estado y proceso de entrega de los Bonos digitales. Estos serán de responsabilidad exclusiva de la Empresa proveedora Quantum Colombia.</p>
    <p className="mc-terms-container__parr">4. El incumplimiento de sus obligaciones por causas atribuibles al Cliente.</p>
    <p className="mc-terms-container__parr">5. Los impuestos y/o gastos no especificados en los presentes términos y condiciones.</p>
    <p className="mc-terms-container__parr">6. Al aceptar el Premio se entenderá que los Clientes ganadores: Liberan al Banco de cualquier responsabilidad que puedan sufrir por el uso del Incentivo.</p>
    <p className="mc-terms-container__parr">7. La responsabilidad del Banco culmina con la entrega del Premio.</p>
    <p className="mc-terms-container__parr">8. Los daños que pueda sufrir el Cliente o que pueda ocasionar el Cliente a terceros en el uso del Premio.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>7. CONDICIONES GENERALES</strong></p>
    <p className="mc-terms-container__parr">• Beneficio no acumulable con otras promociones y/o beneficios no mencionados en esta Campaña.</p>
    <p className="mc-terms-container__parr">• Si por cualquier motivo o causa la presente Campaña no se puede llevar a cabo como se ha planeado, o porque, según la opinión exclusiva de Banco de Occidente S.A. podría afectar o perjudicar a la administración, el funcionamiento, la seguridad, la transparencia, la integridad o imagen de la Compañía, Banco de Occidente S.A. se reserva el derecho, a su entera discreción, de cancelar, finalizar, modificar o suspender la Campaña.</p>
    <p className="mc-terms-container__parr">• Cualquier intento de alguna persona por perjudicar el funcionamiento legítimo de esta actividad o de la aplicación podrá constituir una violación de las leyes civiles y penales, y en caso de producirse el intento, Banco de Occidente S.A. se reserva el derecho de solicitar indemnizaciones a dicha persona dentro de los límites de la ley.</p>
    <p className="mc-terms-container__parr">• Al participar el Tarjetahabiente entiende y acepta que el Banco de Occidente contrató con terceros, por lo tanto, no asume responsabilidad en la calidad de los productos o servicios redimidos o cualquier aspecto relacionado con el servicio, la garantía e idoneidad de los productos y/o servicios adquiridos por los Tarjetahabientes.</p>
    <p className="mc-terms-container__parr">• Las imágenes de los Premios publicadas en las comunicaciones son meramente de referencia.</p>
    <p className="mc-terms-container__parr">• El Banco de Occidente se reserva el derecho, a su exclusiva discreción, de invalidar cualquier y toda participación de cualquier Tarjetahabiente que el Banco considere ha tratado de manipular de forma ilícita o perjudicar la administración, la seguridad, la equidad o el desarrollo correcto de esta campaña. Serán descalificados participantes que:</p>
    <ul>o Hayan realizado, a juicio del Banco cualquier tipo de maniobra engañosa o fraudulenta para incrementar sus posibilidades reales de ser un posible ganador.</ul>
    <ul>o Hayan suministrado al Banco cualquier tipo de datos o información que no sea verídica o que sea incorrecta.</ul>
    <ul>o Se identifique el incumplimiento, por parte del Participante, de alguna de las condiciones establecidas para la Campaña o cualquier acto de defraudación o fraude por parte de éste hacia el Organizador de la Promoción.</ul>
    <ul>o En caso de que el Banco determine que alguno de los Participantes ha violado alguna de las reglas fijadas en los presentes Términos y Condiciones, procederá a su inmediata exclusión. Si como resultado de la información falsa y/o las acciones fraudulentas del Participante, resultase perjudicado un tercero y éste formulase cualquier tipo de reclamación en contra del Banco, este último tomará las acciones legales correspondientes en contra del Participante para obtener la reparación de los daños y perjuicios ocasionados como consecuencia de dicha reclamación.</ul>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>8. POLÍTICA DE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</strong></p>
    <p className="mc-terms-container__parr">Al aceptar participar en la Campaña, el Cliente autoriza a Banco de Occidente S.A. (responsable del Tratamiento) a contactarlo vía mensaje para pedir datos de carácter personal, incluido el nombre, apellido, cédula, celular y dirección de correo electrónico, sin perjuicio y sujeto a las demás autorizaciones que el Cliente brinde al Banco de Occidente en su vinculación o apertura de sus productos. Los datos serán incorporados en los ficheros del banco y se publicará el nombre completo del ganador. Al brindar los datos, el Cliente autoriza al Banco de Occidente para utilizar la información suministrada voluntariamente para el desarrollo de esta actividad.</p>
    <p className="mc-terms-container__parr">En cumplimiento de lo dispuesto en el artículo 12 de la Ley 1581 de 2012 y demás normas que lo modifiquen, se informa al Cliente que, respecto a la información y demás datos personales suministrados en desarrollo de la Actividad, que sus datos no podrán ser utilizados para fines distintos de los establecidos en el concurso. No se podrán usar los datos entregados para otros concursos ni para vender u ofrecer productos del Banco. Así mismo, el Cliente tiene derecho a:</p>
    <ul>• Conocer, actualizar y rectificar dicha información y datos personales frente a los responsables o encargados de su tratamiento.</ul>
    <ul>• Solicitar prueba de la autorización otorgada al responsable del tratamiento. Para tales efectos se entiende que, por el hecho de participar en la Actividad, el Cliente está otorgando dicha autorización, razón por la cual la prueba aludida podrá consistir en la acreditación de dicha circunstancia, a través del medio que mejor corresponda.</ul>
    <ul>• Ser informado por el responsable o el encargado del tratamiento, previa solicitud, del uso que les ha dado a sus datos.</ul>
    <ul>• Presentar ante la Superintendencia Financiera de Colombia quejas por infracciones a las disposiciones contenidas en la Ley 1581 de 2012 (Ley de Protección de Datos), y demás normas que la adicionen, reglamenten o complementen.</ul>
    <ul>• Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías de orden legal y constitucional.</ul>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>9. PROPIEDAD INTELECTUAL</strong></p>
    <p className="mc-terms-container__parr">Banco de Occidente S.A. es titular de todos los derechos de propiedad intelectual entre los que se comprenden los diseños, códigos fuentes, contenidos, información, sonidos, gráficos y dibujos, así como también es titular de los derechos de Propiedad Industrial referidos a sus productos, específicamente de los relativos a las marcas registradas. Por lo anterior, si el Cliente accede a estos Términos y Condiciones, acepta y se obliga a no reproducir, retransmitir, distribuir, vender, publicar, divulgar, circular o comercializar, y en general a no disponer por cualquier medio de la información aquí contenida, total o parcialmente, salvo que exista autorización previa, expresa y escrita de Banco de Occidente S.A.</p>
    <p className="mc-terms-container__parr">El ganador responderá ante Banco de Occidente por cualquier violación a propiedad intelectual y derechos de terceros que con ocasión de su publicación en el concurso se pudieran generar.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>10. MODIFICACIONES:</strong></p>
    <p className="mc-terms-container__parr">Los presentes Términos y Condiciones podrán modificarse, adicionarse o renovarse cuando se considere pertinente, y sin necesidad de autorización por parte del Participante, o por disposición de la ley vigente aplicable, los cuales se notificarán a través de la plataforma de la Campaña <strong className='tyc-black'>o</strong> a través de correo electrónico.</p>
    <p className="mc-terms-container__parr">El Cliente está llamado a consultar los cambios y se entiende su aceptación como requisito indispensable para continuar accediendo y participando en la actividad.</p>
  </div>
  <div>
    <p className="mc-terms-container__parr"><strong className='tyc-black'>11. CONTACTO:</strong></p>
    <p className="mc-terms-container__parr">Si usted tiene alguna inquietud, comentario, solicitud o pregunta relacionada con estos Términos y Condiciones, o las prácticas de información de este sitio por favor comuníquese con nosotros a la <strong className='tyc-black'>Línea de Servicio al Cliente</strong> en <strong className='tyc-black'>Bogotá</strong> al <strong className='tyc-black'>601 390 20 58</strong> y a <strong className='tyc-black'>Nivel Nacional 01 8000 51 46 52.</strong></p>
  </div>
</div>
        </div>
      </Container>
    </div>
  )
}
export default Terms