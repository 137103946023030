import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import Container from '../../layouts/Container/Container'
import { sendDataLayer } from '../../utils/functions';

function MajorAward() {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])


  const layerFaqs = () => {
    sendDataLayer({ datalayer: { event: 'grand_prize', link: '/preguntas-frecuentes', text: 'preguntas frecuentes'}, repeat: true })
  }

  const layerTerms = () => {
    sendDataLayer({ datalayer: { event: 'grand_prize', link: '/terminos-y-condiciones', text: 'Términos y condiciones'}, repeat: true })
  }

  


  return (
    <div className='mc-page alliances alliancesMajor slow_ani '>

      {/*  */}
      <div className='containerAlliances is-major-award'>
        <Container>

          {isDesktop ? (
            <>
              <h1 className='informationTextTitle'>
                ¡$80.000.000 para <br />
                estrenar carro!
              </h1>
              <p className="informationTextPar">
                Conviértete en el cliente que más veces cumplió su meta, <br />
                redime tus bonos y podrás disfrutar de un <br />
                bono para comprar carro nuevo.
              </p>
            </>
          ) : (
            <>
              <h1 className='informationTextTitle'>
                ¡$80.000.000 para estrenar carro!
              </h1>
              <p className="informationTextPar">
                Conviértete en el cliente que más veces cumplió su meta, redime tus bonos y podrás disfrutar de un bono para comprar carro nuevo.
              </p>
            </>
          )}
        </Container>
      </div>
      <Container>
        <div className="major-items">

          <div className="major-items__item">
            <div className="major-items__items--image">
              <img src="/assets/images/major-bono-1.png" />
            </div>
            <div className="major-items__items--labels">
              <h3>¿De qué se trata el bono para comprar un carro?</h3>
              <p>Alcanza tu meta más veces que los demás y redime al menos dos bonos en tus comercios favoritos para participar por un bono de <strong>$80.000.000</strong> para tu nuevo carro. Si su precio es mayor, solo cubrirás la diferencia.</p>
            </div>
          </div>
          <div className="major-items__item">
            <div className="major-items__items--image">
              <img src="/assets/images/major-bono-2.png" />
            </div>
            <div className="major-items__items--labels">
              <h3>¿Qué marcas puedes elegir?</h3>
              <img src="/assets/images/car-brands.png" className='content-only-desktop' />
              <img src="/assets/images/car-brands-m.png" className='content-only-mobile' />
            </div>
          </div>
          <div className="major-items__item">
            <div className="major-items__items--image">
              <img src="/assets/images/major-bono-3.png" />
            </div>
            <div className="major-items__items--labels">
              <h3>¿Aplica para todas las ciudades?</h3>
              <p>
                <strong>Ciudades incluidas:</strong> <br />
                Bogotá, Medellín, Cali, Barranquilla, Cartagena, Manizales, Pereira, Armenia, Santa Marta, Valledupar, Montería, Neiva, Ibagué, Villavicencio, Tunja y Bucaramanga.
              </p>
              <p>Si ganas y no estás en las ciudades mencionadas, coordinaremos contigo cómo hacer la redención.</p>
            </div>
          </div>


        </div>

        <div className="extra-banner">
          <h3>¿Necesitas más información?</h3>
            <p>
              Consulta los <a onClick={layerTerms} target='_blank' href="/terminos-y-condiciones">términos y condiciones</a> y las <a onClick={layerFaqs} target='_blank' href="/preguntas-frecuentes">preguntas frecuentes</a> de la campaña, Hacer es Ganar. y te dejo TyC
            </p>
        </div>
      </Container>

    </div >
  )
}
export default MajorAward