import React from 'react'
import Container from '../../layouts/Container/Container'
import Button from '../atoms/Button'

function NotFound() {

  return (
    <div className='mc-page not-found slow_ani page-notfound'>
      <div className="mc-container-not-found">
        <Container>
          <div className="mc-container-not-found__image">
            <img src="/assets/images/404.png" alt="not found image" />
          </div>
          <div>

            <div>
              <p className="informationTextPar informationTextParNotFound"><strong>No encontramos lo que estabas <br /> buscando, pero…</strong></p>
              <p className="informationTextParSmall">Puedes revisar cómo va tu meta para que sigas <br /> comprando con tu Tarjeta de Crédito Mastercard y <br /> canjear premios.</p>
            </div>
            <div className="container-button-notfound">
              <Button
                type="primary"
                text={'Ir al progreso'}
                loading={false}
                status={'active'}
                link="/progreso"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default NotFound